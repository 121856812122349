<template>
  <div id="drag-vertical" :class="{ visible: bSliderVisible }">
    <div class="btn slider-close-button" @click="hide()">
      <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 14" overflow="visible" xml:space="preserve">
        <path fill="#000" d="M14,1.4L12.6,0L7,5.6L1.4,0L0,1.4L5.6,7L0,12.6L1.4,14L7,8.4l5.6,5.6l1.4-1.4L8.4,7L14,1.4z"/>
      </svg>
    </div>
    <div v-if="slider && dotHelper.length > 1" class="dots">
      <button
        v-for="(_slide, idx) in dotHelper"
        @click="slider.moveToIdx(idx)"
        :class="{ dot: true, active: iSlideIndex === idx }"
        :key="idx"
      ></button>
    </div>
    <div ref="keenSlider" id="drag-horizontal" class="keen-slider">
      <div class="keen-slider__slide" v-for="artwork in artworks" :key="artwork.id">
        <Artwork :context="context" :artwork="artwork" :text="text" :currentLanguage="currentLanguage" :positionTick="positionTick" />
      </div>
    </div>
  </div>
</template>

<script>
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';
import Artwork from '@/components/Artwork.vue';

export default {
  name: 'Artworks',
  components: {
    Artwork
  },
  props: {
    context: Object,
    artworks: Array,
    show: Boolean,
    text: Object,
    currentLanguage: String,
    positionTick: Number
  },
  data() {
    return {
      container: '',
      slider: null,
      bScrollHorizontal: false,
      bSliderVisible: false,
      iSlideIndex: 0,
      initialized: false,
      distancePassed: null
    };
  },
  updated() {
    this.$nextTick(function () {
      if (this.initialized) return;
      this.initialized = true;
      this.init(!!this.artworks.length);
    });
  },
  computed: {
    dotHelper() {
      return this.slider ? [...Array(this.slider?.track?.details?.slides.length).keys()] : [];
    }
  },
  watch: {
    show() {
      if (!this.slider) this.init(!!this.artworks.length);
      if (!this.show) this.resetAllSlides();
      else this.updateLocalStorage({ r: this.artworks[0] ? { a: this.artworks[0].works_id.id, e: this.exhibitionId } : null });
    },
    artworks() {
      this.initialized = false;
      this.init(!!this.artworks.length);
    }
  },
  mounted() {
    this.init(false);
  },
  methods: {
    /**
     * Artworks main initialization.
     *
     * @param {boolean} bSetSliderVisible Set sliders visibility state after creation
     */
    init(bSetSliderVisible = true) {
      this.destroy();
      this.iSlideIndex = 0;

      const oStaticSlidesParameters = {
        origin: 'center',
        spacing: 0
      };

      this.slider = new KeenSlider(
        this.$refs.keenSlider,
        {
          slides: {
            ...oStaticSlidesParameters,
            perView: 1,
            rubberband: false,
          },
          breakpoints: {
            '(min-width: 768px)': {
              slides: {
                ...oStaticSlidesParameters,
                perView: 1.15,
              },
            },
            '(min-width: 1024px)': {
              slides: {
                ...oStaticSlidesParameters,
                perView: 1.5,
              },
            },
          },
          created: () => {
            this.bSliderVisible = bSetSliderVisible;
            [...document.querySelectorAll('#drag-horizontal .drag-y .card-wrapper')].forEach(oElem => oElem.scrollTo(0, 0)); // scroll all cards to top
          },
          slideChanged: async (oSlider) => {
            this.bScrollHorizontal = false;
            const iCurrentIndex = await oSlider.track.details.rel;


            if (iCurrentIndex !== this.iSlideIndex) {
              this.iSlideIndex = oSlider.track.details.rel;
              this.updateLocalStorage({ r: this.artworks[this.iSlideIndex] ? { a: this.artworks[this.iSlideIndex].works_id.id, e: this.exhibitionId } : null });
              this.resetAllSlides(); // Remove vertical offset
            }
          },
          dragChecked: () => this.bScrollHorizontal = true
        }
      );
    },

    /**
     * Hide slider
     */
    hide() {
      this.updateLocalStorage({ r: null }); // Reset return to artwork
      this.context.hideArtworks();
    },

    /**
     * Remove slider
     */
    destroy() {
      this.slider?.destroy();
    },

    /**
     * Set initial position for all slider elements via DOM
     */
    resetAllSlides() {
      // Remove vertical offset
      [...document.querySelectorAll('#drag-horizontal .drag-y')].forEach(oElem => {
        oElem.classList.remove('no-transition');
        setTimeout(() => {
          oElem.style.transform = 'translateY(0)';
          oElem.setAttribute('data-start', '0');
          oElem.setAttribute('data-stop', '0');
          oElem.setAttribute('data-drag', '0');
          oElem.setAttribute('data-dragging', 'false');
          oElem.classList.remove('allow-scroll');
          oElem.scrollTo(0, 0);
        }, 1);
      });
    },

    /**
     * Switch to another slide
     *
     * @param {number} i Index of requested slide
     */
    moveToSlide(i) {
      this.slider.moveToIdx(i);
    }
  }
}
</script>

<style lang="scss">
@import "../scss/_artworks.scss";
</style>
