<template>
  <li class="flip" :data-flip="showBackside">
    <div v-if="doubleSided" class="flip-inner">
      <div class="flip-front">
        <button type="button" class="btn">
          <Icon :name="icon_front" />
        </button>
      </div>
      <div class="flip-back">
        <button type="button" class="btn">
          <Icon :name="icon_back" />
        </button>
      </div>
    </div>
  </li>
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
  name: 'MenuItem',
  props: {
    doubleSided: {
      type: Boolean,
      default: false
    },
    showBackside: Boolean,
    icon_front: String,
    icon_back: String
  },
  components: {
    Icon
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/_menuitem.scss";
</style>
