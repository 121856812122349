<template>
  <router-view />
</template>

<script>
import { fnSetCssVariables } from '@/modules/globalFunctions.js';

export default {
  // Global meta informations, specified informations in each view
  metaInfo: {
    titleTemplate: 'ARX Africa AR',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'UTF-8' },
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'theme-color', content: '#e94d18' }
    ],
    link: [
      { rel: 'icon', type: 'image/svg+xml', href: 'https://arxafrica.net/images/favicon.svg', sizes: 'any' }
    ]
  }
}

// Set CSS variables
window.addEventListener('resize', fnSetCssVariables);
window.addEventListener('orientationchange', fnSetCssVariables);
fnSetCssVariables();
</script>

<style lang="scss">
@import "./scss/_global.scss";
</style>
