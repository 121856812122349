<template>
  <div class="drag-y" v-if="artwork" :data-type="artwork.locationtype">
    <!-- removed options from the div above @touchstart="fnDragStart($event)" @touchmove="fnDragMove" @touchend="fnDragEnd" @scroll="fnScroll" :data-start="0" :data-stop="0" :data-drag="0" :data-max="-800" :data-dragging="false" -->

    <div class="card-wrapper" ref="cardWrapper">
      <div class="intro">
        <div class="header">
          <div class="header-text">
            <h2>{{ artwork.works_id.title }}</h2>
            <h3>{{ artwork.works_id.user_created.first_name }} {{ artwork.works_id.user_created.last_name }}</h3>
          </div>
          <img v-if="previewImage" :src="previewImage" :alt="artwork.works_id.title" :show="isPreviewLoaded" @load="onPreviewLoad" />
        </div>
        <a :id="`ar_btn_${artwork.works_id.id}`" :rel="isApple ? 'ar' : 'nofollow'" :href="isApple ? arLinkApple : arLinkAndroid" class="hidden-link"><img width="15" height="15" src="../assets/ar.svg" /></a>
      </div>
      <div class="content">
        <div v-if="artwork.locationtype === 'indoor' || (artwork.locationtype === 'outdoor' && distance !== null)" class="state">
          <span v-if="artwork.locationtype === 'indoor'"                  >{{ text.viewer_artwork_indoor }}</span>
          <span v-else-if="artwork.locationtype === 'outdoor' && !inRange">{{ (text.viewer_artwork_outdoor || '').split('__distance__').shift() }} <span class="distance">{{ distance }}</span> {{ (text.viewer_artwork_outdoor || '').split('__distance__').pop() }}</span>
          <span v-else-if="artwork.locationtype === 'outdoor' && inRange" >{{ text.viewer_artwork_outdoor_reached }}</span>
        </div>
        <div class="info-artwork" v-if="artwork.works_id.description_original">
          <TranslateToggle :language="currentLanguage" :original="replaceLinkTags(artwork.works_id.description_original)" :translated="replaceLinkTags(artwork.works_id.description)" :text="text" />
        </div>
        <div class="info-artist">
          <h3>{{ artwork.works_id.user_created.first_name }} {{ artwork.works_id.user_created.last_name }}</h3>
          <p class="subtitle">{{ [artwork.works_id.user_created.birthday, artwork.works_id.user_created.country].filter(s => s && s.trim()).join(', ') }}</p>
          <div class="grid-50-50">
            <img :src="artwork.works_id.user_created.avatar ? url + artwork.works_id.user_created.avatar + '/avatar' : 'https://arxafrica.net/images/placeholder_user.svg'" :alt="artwork.works_id.title" width="100" height="100" draggable="false" />
            <div class="africa-map-container">
              <div class="arxmap">
                <Icon name="arxmap" :selectedDots="countries" />
              </div>
            </div>
          </div>
          <div class="buttons">
            <div v-for="item, i in socialLinks" :key="i">
              <a v-if="item.href" class="btn icon-svg" target="_blank" aria-hidden="true" :href="`${item.key === 'email' ? 'mailto:' : ''}${item.href}`">
                <Icon :name="item.key" />
              </a>
            </div>
          </div>
          <TranslateToggle v-if="artwork.works_id.user_created.description_original" :language="currentLanguage" :original="replaceLinkTags(artwork.works_id.user_created.description_original)" :translated="replaceLinkTags(artwork.works_id.user_created.description)" :text="text" />
        </div>
      </div>
    </div>
    <div class="actions">
      <button type="button" class="btn like" :class="{ 'is-liked': isLiked }" @click="toggleLike()"><Icon name="like" /></button>

      <button type="button" class="btn btn-loader" :class="{ disabled: artwork.locationtype === 'outdoor' && !inRange && !context.ignoreGpsRange, processing: blockArButton }" @click="setVisited($event)">
        <span v-if="!blockArButton">{{ text.viewer_view_artwork }}</span>
        <span v-else>{{ text.viewer_loading_model }}</span>
      </button>

      <button v-if="isShareable" type="button" class="btn share" @click="share()"><Icon name="share" /></button>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import * as turf from '@turf/turf';
import Icon from '@/components/Icon.vue';
import TranslateToggle from '@/components/TranslateToggle.vue';
import { fnShareContent, fnRequestDeviceOrientation } from '@/modules/globalFunctions.js';

export default {
  name: 'Artwork',
  props: {
    context: Object,
    artwork: Object,
    text: Object,
    currentLanguage: String,
    positionTick: Number
  },
  components: {
    Icon,
    TranslateToggle
  },
  data() {
    return {
      // General
      isApple: false,
      url: process.env.VUE_APP_ASSET_URL,
      modelUrl: process.env.VUE_APP_MODEL_URL,

      isPreviewLoaded: false,

      // Positioning
      iScrollThreshold: Math.ceil(Math.min(window.innerHeight / 10, 5)),
      draggedElem: null,
      position: {
        initial: {
          startPos: 0,
          isTop: false,
          isBottom: true,
          scrollThresholdExceeded: false,
          direction: null,
          lastStart: 0
        },
        startPos: 0,
        isTop: false,
        isBottom: true,
        scrollThresholdExceeded: false,
        direction: null,
        lastStart: 0
      },

      // Files
      previewImage: null,
      arLinkAndroid: null,
      arLinkApple: null,

      // Artist data
      socialLinks: {},
      countries: [],

      // Artwork data
      isLiked: false,
      isVisited: false,
      inRange: false,
      isShareable: false,
      artworkData: {
        a: null,
        e: null
      },
      showOriginal: {
        artwork: false,
        artist: false
      },
      distance: null
    };
  },
  watch: {
    artwork() {
      this.init();
    },
    positionTick() {
      this.updateInRange();
    }
  },
  updated() {
    this.$nextTick(function () {
      this.updateInRange();
    });
  },
  async mounted() {
    this.init();
  },
  methods: {
    /**
     * Components main initialization function
     */
    init() {
      // Get files
      const aFiles = this.artwork.works_id.directus_files;
      this.previewImage = this.getFileUrl(aFiles, 'image');
      this.arLinkAndroid = this.getFileUrl(aFiles, 'gltf');
      this.arLinkApple = this.getFileUrl(aFiles, 'usdz');

      // Get device type
      this.isApple = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'iPhone', 'iPad', 'iPod'].indexOf(window.navigator.platform) !== -1;

      // Add social items, fill missing social items with empty boxes
      const aAllowedSocialKeys = [
        'website',
        'email',
        'twitter',
        'youtube',
        'facebook',
        'instagram',
        'vimeo',
        'tiktok'
      ];
      this.socialLinks = [];
      aAllowedSocialKeys.forEach(sKey => {
        const sValue = (this.artwork.works_id.user_created.social_links || {})[sKey] || null;
        if (sValue) this.socialLinks.push({ key: sKey, href: sValue });
      });

      // Afrika map, selected dots
      this.countries = (this.artwork.works_id.user_created.countries || []).map(oCountry => oCountry.code);

      this.artworkData = {
        a: this.artwork.works_id.id.toString(),
        e: this.exhibitionId
      };

      this.isShareable = this.artwork.works_id.status === 'published';

      this.getLiked();
      this.getVisited();
      this.updateInRange();
    },

    /**
     * Returns either image url or AR link
     *
     * @param {{
     *   id: string;
     *   type: string;
     *   filename_download: string;
     * }[]} aFiles
     * @param {'image'|'usdz'|'gltf'} sType
     * @returns {string|null}
     */
    getFileUrl(aFiles, sType) {
      const sFallbackUrl = window.location.href;

      const oSettings = {
        allowResize: this.artwork.scaling === 'auto',
        placeAtWall: this.artwork.works_id.placement === 'wall'
      };

      if (sType === 'image') {
        const sVimeoPreview = this.getArtworkPreview(this.artwork.works_id.id.toString());
        if (sVimeoPreview) return sVimeoPreview;
        const oImage = aFiles.find(oFile => oFile.type.split('/').shift() === sType);
        if (oImage) return `${this.url}${oImage.id}/preview`;
      } else if (sType === 'usdz') {
        // iOS AR link
        const oUsdz = aFiles.find(oFile => oFile.filename_download.split('.').pop() === 'usdz');
        if (oUsdz) return `${this.modelUrl}${oUsdz.id}.usdz#allowsContentScaling=${oSettings.allowResize ? 1 : 0}&canonicalWebPageURL=${sFallbackUrl}`;
      } else if (sType === 'gltf') {
        // Android AR link
        const oGltf = aFiles.find(oFile => (oFile.filename_download.split('.').pop() === 'glft' || oFile.filename_download.split('.').pop() === 'glb'));
        if (oGltf) {
          const aParams = [
            'mode=ar_preferred',
            `resizable=${JSON.stringify(oSettings.allowResize)}`,
            `enable_vertical_placement=${JSON.stringify(oSettings.placeAtWall)}`,
            `file=${encodeURIComponent(this.modelUrl + oGltf.id + '.' + oGltf.filename_download.split('.').pop())}`,
          ];
          return `intent://arvr.google.com/scene-viewer/1.0?${aParams.join('&')}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(sFallbackUrl)};end;`;
        }
      }
      return null;
    },
    // check if image is loaded
    onPreviewLoad () {
      console.log('loaded');
      this.isPreviewLoaded = true;
    },

    /**
     * Get liked state of the current artwork
     *
     * @returns {{
     *   found: boolean;
     *   index: number;
     * }}
     */
    getLiked() {
      // Check if like is set
      let iLikeIndex = 0;
      this.fetchLocalStorage();

      const iMax = this.localStorage.l.length;
      for (null; iLikeIndex < iMax; iLikeIndex++) {
        if (this.localStorage.l[iLikeIndex].e === this.artworkData.e && this.localStorage.l[iLikeIndex].a === this.artworkData.a) break;
      }

      this.isLiked = iLikeIndex < iMax;

      return {
        found: iLikeIndex < iMax,
        index: iLikeIndex
      };
    },

    /**
     * Toggle liked state for the current artwork.
     * Data gets stored in Directus collection 'likes'
     */
    async toggleLike() {
      const { found: bIsLiked, index: iLikeIndex } = this.getLiked();
      const bSetLike = !bIsLiked;

      const bSuccess = await new Promise(resolve => {
        $.ajax({
          url: 'https://arxafrica.net/lib/php/data/like.php',
          type: 'POST',
          data: {
            artwork: this.artworkData.a,
            exhibition: this.artworkData.e,
            action: bSetLike ? 'like' : 'unlike'
          },
          success: () => resolve(true),
          error: () => resolve(false)
        });
      });

      if (bSuccess) {
        this.fetchLocalStorage();
        const aLikes = this.localStorage.l;
        bSetLike
          ? aLikes.push(this.artworkData) // Add like
          : aLikes.splice(iLikeIndex, 1); // Already liked -> Remove like
        this.updateLocalStorage({ l: aLikes });

        // Refresh like state
        this.getLiked();

        console.log(`${bSetLike ? 'Added' : 'Removed'} a like for artwork ${this.artworkData.a}`);
      }
    },

    /**
     * Share artwork
     */
    share() {
      fnShareContent(
        (this.text.viewer_share_title || 'ARX Africa | __title__').replace(new RegExp(/__title__/, 'im'), this.artwork.works_id.title),
        `https://arxafrica.net/${this.currentLanguage || 'en'}/shared/${this.artwork.works_id.id}`,
        (this.text.viewer_share_text || `I found the artwork '__title__' at ARX Africa! Check this out!`).replace(new RegExp(/__title__/, 'im'), this.artwork.works_id.title),
        {
          success: () => $('#share').attr('aria-hidden', 'true'),
          noSupport: () => $('#share').attr('aria-hidden', 'true')
        }
      )
    },

    /**
     * Get visited state for current artwork
     * @returns {boolean}
     */
    getVisited() {
      // Check if like is set
      let iVisitedIndex = 0;
      const iMax = this.localStorage.v.length;
      for (null; iVisitedIndex < iMax; iVisitedIndex++) {
        if (this.localStorage.v[iVisitedIndex].e === this.artworkData.e && this.localStorage.v[iVisitedIndex].a === this.artworkData.a) break;
      }

      this.isVisited = iVisitedIndex < iMax;

      return this.isVisited;
    },

    /**
     * Runs custom code before opening AR viewer.
     * Sets visited state for current artwork to true
     */
    async setVisited() {
      // Return if user is not near artwork or AR button is blocked
      if (this.artwork.locationtype === 'outdoor' && !this.inRange && !this.context.ignoreGpsRange) return;
      else if (this.blockArButton) return;

      const oLink = document.querySelector(`#ar_btn_${this.artwork.works_id.id}`);

      this.context.qr.scanner?.stop();
      this.context.qr.scanner?.destroy();
      this.context.qr.scanner = null;

      if (!this.isVisited) {
        this.fetchLocalStorage();
        const aVisited = this.localStorage.v;
        aVisited.push(this.artworkData);

        // Change marker design to visited
        [...document.querySelectorAll('#map .custom-marker-interact')].forEach(oElem => {
          const { group: aGroup } = oElem.dataset || {};
          const aIds = (aGroup || '').split(',');
          if (aIds.indexOf(this.artworkData.a) !== -1) oElem.classList.add('visited');
        });

        this.updateLocalStorage({ v: aVisited });
      }



      await new Promise(resolve => setTimeout(() => resolve(), 50));

      const fnSimulateClick = function (oElem) {
        // Create our event (with options)
        const oEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        });
        oElem.dispatchEvent(oEvent);
      };

      if (this.artwork.works_id.type === 'video') this.showVideo(this.artwork.works_id.vimeo_id);
      else if (oLink && !this.blockArButton) fnSimulateClick(oLink);

      // Block AR button
      if (this.artwork.works_id.type !== 'video' && oLink && !this.blockArButton) this.blockArButton = true;

      setTimeout(() => {
        this.blockArButton = false;
      }, 2000);
    },

    /**
     * Append iframe to Aframe block
     *
     * @param {string} sVimeoId
     */
    async showVideo(sVimeoId) {
      await fnRequestDeviceOrientation();
      const oIframe = document.createElement('iframe');
      oIframe.setAttribute('src', `./video/index.php?id=${sVimeoId}`);
      this.context.$refs.Aframe.appendChild(oIframe);
      this.context.showAframe = true;
    },

    /**
     * Check if user is in the artworks range
     */
    updateInRange() {
      if (!this.artwork) return;

      this.fetchLocalStorage();
      const iDistance = Math.floor(turf.distance(
        this.localStorage.p,
        [this.artwork.longitude, this.artwork.latitude],
        { units: 'kilometers' }
      ) * 1000);

      this.inRange = iDistance <= this.artwork.range;
      this.distance = iDistance;
    },

    /**
     * Remove all a-tags from a HTML string
     *
     * @param {string} sHtml
     * @returns {string}
     */
    replaceLinkTags(sHtml) {
      return sHtml.replace(/<a(?:[\s]+)(?:[^>]+)>((?:(?:.(?!<\/a>))*.)|)<\/a>/g, `[${this.text.viewer_replaced_link}]`);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/_artwork.scss";
</style>
