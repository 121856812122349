<template>
  <video autoplay muted playsinline :class="{ visible: show }" id="videostream"></video>
</template>

<script>
import { fnGetDocumentHidden } from '@/modules/globalFunctions.js';

export default {
  name: 'CameraStream',
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      show: false,
      stream: null
    };
  },
  watch: {
    active() {
      this[this.active ? 'start' : 'stop']();
    }
  },
  mounted() {
    this[this.active ? 'start' : 'stop']();

    document.addEventListener('visibilitychange', () => {
      const oHidden = fnGetDocumentHidden();

      this[document[oHidden.state] === 'visible' && this.active ? 'start' : 'stop']();
    });
  },
  methods: {
    /**
     * Initialize the camera stream
     */
    start() {
      navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: 'environment' } })
        .then(async oMediaStream => {
          this.show = true;
          this.stream = oMediaStream;

          let oVideoElem = null;
          await new Promise(resolve => {
            const iInterval = setInterval(() => {
              oVideoElem = document.querySelector('#videostream');
              if (oVideoElem) {
                clearInterval(iInterval);
                resolve();
              }
            }, 200);

            // Timeout
            setTimeout(() => resolve(), 5000);
          });

          if (oVideoElem) {
            oVideoElem.srcObject = oMediaStream;
            oVideoElem.onloadedmetadata = () => oVideoElem.play();
          } else {
            console.log('Video element is not existing!');
          }
        })
        .catch(oError => {
          console.log(`${oError.name}: ${oError.message}`);
          this.stream = null;
          this.show = false;
        });
    },

    /**
     * Close the camera stream
     */
    stop() {
      this.stream?.getTracks().forEach(oTrack => oTrack.stop());
      this.stream = null;
      this.show = false;
    }
  }
}
</script>

<style lang="scss" scoped>
#videostream {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translateX(-50%) translateY(-50%) !important;
  display: none;

  &.visible {
    display: block;
  }
}
</style>
