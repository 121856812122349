<template>
  <header>
    <div id="header-container">
      <button id="menu-button" class="menu-button" aria-label="Menü" aria-expanded="false">
        <svg width="100%" height="100%" viewBox="0 0 100 100">
          <path class="line" stroke="currentColor" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path>
          <path class="line" stroke="currentColor" d="M 20,50 H 80"></path>
          <path class="line" stroke="currentColor" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path>
        </svg>
      </button>

      <div id="menu-content-overlay" data-redirect-click="menu-button">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <nav id="menu-content">
        <ul>
          <li>
            <div id="menu-language" class="custom-dropdown">
              <button id="menu-language-btn" type="button" aria-labelledby="menu-language-btn" aria-haspopup="listbox" aria-expanded="false">{{ langTexts[localStorage.L] }}</button>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path></svg>
              <ul id="menu-language-list" tabindex="-1" role="listbox" aria-labelledby="" class="hidden">
                <li id="menu-language-en" role="option" :data-label="langTexts.en" @click="setLanguage('en')">{{ langTexts.en }}</li>
                <li id="menu-language-de" role="option" :data-label="langTexts.de" @click="setLanguage('de')">{{ langTexts.de }}</li>
                <li id="menu-language-fr" role="option" :data-label="langTexts.fr" @click="setLanguage('fr')">{{ langTexts.fr }}</li>
              </ul>
            </div>
          </li>
          <li role="separator" class="separator-small"></li>
          <li><router-link :to="`/list`" class="big">{{ texts.viewer_menu_overview }}</router-link></li>
          <li><router-link :to="`/tutorial`" class="big">{{ texts.viewer_tutorial }}</router-link></li>

          <li role="separator" class="separator-auto no-desktop"></li>
          <li class="no-desktop"><span class="small" @click="resetApp()">{{ texts.viewer_reset_app }}</span></li>
          <li role="separator" class="separator-tiny"></li>
          <li class="no-desktop"><router-link :to="`/requirements`" class="small">{{ texts.requirements }}</router-link></li>
          <li class="no-desktop"><a :href="`https://arxafrica.net/${this.localStorage.L}/impressum`" class="small">{{ texts.impressum }}</a></li>
          <li class="no-desktop"><a :href="`https://arxafrica.net/${this.localStorage.L}/dataprotection`" class="small">{{ texts.dataprotection }}</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import { fnCloseMenu, fnGetTexts, fnUpdateStoredTexts, fnRegisterCustomDropdownEvents, fnRegisterMenuButtonEvents, fnRegisterRedirectClickEvent } from '@/modules/globalFunctions.js';

const oTextTemplate = {
  impressum: '',
  dataprotection: '',
  viewer_menu_overview: '',
  viewer_reset_app: '',
  requirements: '',
  viewer_tutorial: ''
};

export default {
  name: 'Header',
  props: {
    onLanguageChange: {
      type: Function,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      language: '',
      texts: {
        ...oTextTemplate
      },
      langTexts: {
        de: 'DE Deutsch',
        en: 'EN English',
        fr: 'FR Français'
      }
    };
  },
  watch: {
    async language() {
      await this.setTexts();
    }
  },
  async mounted() {
    await this.setTexts();

    // Register events on DOM nodes
    fnRegisterCustomDropdownEvents(); // Custom dropdown functions assigned via DOM nodes
    fnRegisterMenuButtonEvents();     // Menu button click event via DOM nodes
    fnRegisterRedirectClickEvent();   // Click event for DOM nodes wit data-redirect-click attribute
  },
  methods: {
    /**
     * Change current language and close the menu
     *
     * @param {'de'|'en'|'fr'} sLanguage Language key
     */
    setLanguage(sLanguage) {
      fnCloseMenu();
      this.language = sLanguage;
      this.fetchLocalStorage();
      if (this.localStorage.L !== sLanguage) this.updateLocalStorage({ L: sLanguage });
      this.onLanguageChange();
    },

    /**
     * Get texts from Directus
     */
    async setTexts() {
      this.fetchLocalStorage();
      const oTexts = await fnGetTexts(this.localStorage.L, 'other_texts', Object.keys(oTextTemplate));

      fnUpdateStoredTexts(oTexts, this.localStorage.L);

      this.texts = {
        ...this.texts,
        ...oTexts || {}
      };
    }
  }
}
</script>
