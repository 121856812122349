<template>
  <div class="info-box">
    <h2 class="title" v-if="title">{{ title }}</h2>
    <p class="text" v-if="msg">{{ msg }}</p>
    <button v-if="btnText1 && fnOnClick1 !== null" class="btn" @click="fnOnClick1">{{ btnText1 }}</button>
    <button v-if="btnText2 && fnOnClick2 !== null" class="btn btn2" @click="fnOnClick2">{{ btnText2 }}</button>
  </div>
</template>

<script>
export default {
  name: 'InfoBox',
  props: {
    title: {
      type: String,
      default: null
    },
    msg: {
      type: String,
      default: null
    },
    fnOnClick1: {
      type: Function,
      default: null
    },
    fnOnClick2: {
      type: Function,
      default: null
    },
    btnText1: {
      type: String,
      default: null
    },
    btnText2: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.info-box {
  position: absolute;
  z-index: 4;
  background: #fff;
  color: #000;
  padding: 1rem;
  max-width: 400px;
  width: 70%;
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);

  .title {
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
  }

  .btn {
    margin: 0.75rem auto 0 auto;
    width: 100%;
  }

  .btn2 {
    background-color: transparent;
    margin-top: 0.5rem;
    font-size: 0.75rem;
    min-height: 1.5rem;
    color: #333;
    padding: 0.25rem 0.5rem;
  }
}
</style>
