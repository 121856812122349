<template>
  <div>
    <div v-if="language !== 'en' && original && translated" class="translated">
      <span v-if="!showOriginal">{{ text.viewer_translated_tag }}</span>
      <label class="toggle-translation">
        <input type="checkbox" v-model="showOriginal">
        <span v-text="showOriginal ? text.viewer_text_translated : text.viewer_text_original"></span>
      </label>
    </div>
    <div v-if="showOriginal" v-html="original"></div>
    <div v-else v-html="translated"></div>
  </div>
</template>

<script>
export default {
  name: 'TranslateToggle',
  props: {
    language: String,
    original: String,
    translated: String,
    text: Object
  },
  data() {
    return {
      showOriginal: false
    }
  },
  methods: {
    /**
     * Toggle visible text.
     * Original <-> Translation
     */
    toggle() {
      this.showOriginal = !this.showOriginal;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";

.translated {
  font-size: 0.7rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  border-bottom: 0.0625rem solid $color-9;

  & > span {
    color: $color-9;
  }

  .toggle-translation {
    margin-left: auto;

    input[type="checkbox"] {
      display: block;
      opacity: 0;
      width: 1px;
      height: 1px;
      margin: -1px 0 0 -1px;
      overflow: hidden;
    }

    span {
      color: $color-1;
    }
  }
}
</style>
