import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import AR from '@/views/AR';
import List from '@/views/List';
import Requirements from '@/views/Requirements';
import TutorialVideo from '@/views/TutorialVideo';
import ErrorPage from '@/views/404';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  { path: '/list', component: List },
  { path: '/requirements', component: Requirements },
  { path: '/tutorial', component: TutorialVideo },
  { path: '/:id(\\d+)', component: AR },
  { path: '/:hash([a-zA-Z0-9\\+=]*)', component: AR },

  // 404
  { path: '*', component: ErrorPage }
];

// Initialize router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// Redirect routing
router.beforeEach((to, from, next) => {
  try {
    next();
  } catch (oError) {
    router.push('/list');
  }
});

export default router;
