<template>
  <div id="intro" class="intro" :class="{ init: !fadeOut, hide: fadeOut }">
    <header>
      <h1 class="slide init"><span>{{ title }}</span></h1>
      <h2 class="slide init"><span>{{ subtitle }}</span></h2>
    </header>

    <div class="stripe init">
      <p v-html="`<span>${text.viewer_intro_text}</span>`"></p>
    </div>

    <div class="stripe init">
      <p class="os-hint" v-html="`<span>${hint}</span>`"></p>
      <button type="button" @click="startExhibition()">{{ text.viewer_intro_button }}</button>
    </div>

    <div class="stripe init">
      <Icon name="logo_text" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
import { Intro as IntroClass } from '@/modules/Intro.class.js';

export default {
  name: 'Intro',
  components: {
    Icon
  },
  props: {
    title: String,
    subtitle: String,
    context: Object,
    hideDelay: {
      type: Number,
      default: 0
    },
    text: Object,
    dataloaded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      blockInteraction: false,
      fadeOut: false,
      hint: '',
    };
  },
  watch: {
    dataloaded() {
      this[this.dataloaded ? 'animate' : 'reset']();
    }
  },
  methods: {
    /**
     * Hide overlaying intro screen and stored exhibition ID to skip intro next time for this exhibition
     */
    startExhibition() {
      if (this.blockInteraction) return;
      this.blockInteraction = true;

      this.fadeOut = true;

      // Save exhibition ID to skip intro next time
      const iExhibitionId = parseInt(this.exhibitionId || '0');
      this.fetchLocalStorage();
      const aSkipIntros = this.localStorage.i || [];
      if (this.exhibitionId && aSkipIntros.indexOf(iExhibitionId) === -1) aSkipIntros.push(iExhibitionId);
      this.updateLocalStorage({ i: aSkipIntros });

      setTimeout(() => this.context.introVisible = false, this.hideDelay);
    },

    /**
     * Animate headings
     */
    animate() {
      // Run intro animation
      const oIntro = new IntroClass('intro');
      oIntro.start();
    },

    /**
     * Reset intro
     */
    reset() {
      this.blockInteraction = false;
      this.fadeOut = false;
    },

    /**
     * Get android version via useragent
     */
    getAndroidVersion() {
      const aMatches = navigator.userAgent.match(/android\s([0-9.]*)/i);
      return aMatches ? aMatches[1] : null;
    },

    /**
     * Set appropriate message according to used useragent
     */
    checkDeviceSupport() {
      const bIsAndroid = new RegExp('android', 'i').test(navigator.userAgent);
      const iAndroidVersion = bIsAndroid ? parseInt(this.getAndroidVersion()) : 0;
      const bNoChromeBrowser = navigator.userAgent.match(/(chrome)[/\s]([\d.]+)/ig) === null;
      const bAppleWarn = this.isApple && (!document.createElement('a').relList.supports('ar') || !window.SpeechSynthesisUtterance);

      this.hint = bAppleWarn
        ? this.text.viewer_ios_version_warning
        : bIsAndroid
          ? iAndroidVersion < 7
            ? this.text.viewer_android_version_warning
            : bNoChromeBrowser
              ? this.text.viewer_use_chrome
              : this.text.viewer_android_hint
          : '';
    }
  },
  async mounted() {
    await this[this.dataloaded ? 'animate' : 'reset']();
    this.checkDeviceSupport();
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/_intro.scss";
</style>
