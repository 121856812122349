<template>
  <div id="body" class="light">
    <Header :onLanguageChange="init"/>

    <div class="video">
      <video src="../assets/videos/tutorial_en_de.mp4" controls></video>
    </div>
  </div>
</template>

<script>
import { oMetaDataTemplate, fnCloseMenu } from '@/modules/globalFunctions.js';
import Header from '@/components/Header.vue';

export default {
  name: 'TutorialVideo',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    Header
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'ar_viewer',
        content: oMetaDataTemplate
      },
      isMobileTablet: false,
      isAllowedBrowser: false,
      supportsAr: false
    };
  },
  watch: {
    $route() {
      this.init();
      fnCloseMenu();
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.fetchLocalStorage();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../scss/_tutorialvideo.scss";
</style>

<style lang="scss">
@import "./../scss/variables";

html,
body {
  background-image: $background-horizontal;
}
</style>
