import $ from 'jquery';
export class Intro {
    headerInstances = {
        h1: false,
        h2: false
    };
    id;
    constructor(sId) {
        this.id = sId;
    }
    animate = async (aAnimations, bAsync = true) => {
        let iTimer = 0;
        if (bAsync) {
            aAnimations.forEach(async (oAnimation) => {
                const $elem = $(oAnimation.selector);
                const iDuration = parseFloat($elem.css('transition-duration')) * 1000;
                iTimer += oAnimation.delay;
                await new Promise((resolve) => setTimeout(() => {
                    $elem.removeClass('init');
                    setTimeout(() => resolve(), iDuration);
                }, iTimer));
                iTimer += iDuration;
            });
        }
        else {
            aAnimations.forEach(oAnimation => {
                const $elem = $(oAnimation.selector);
                const iDuration = parseFloat($elem.css('transition-duration')) * 1000;
                iTimer += oAnimation.delay;
                setTimeout(() => $elem.removeClass('init'), iTimer);
                iTimer += iDuration;
            });
        }
        return iTimer;
    };
    shouldSlide = (sElement) => {
        const $elem = $(`#${this.id} header>${sElement}`);
        if (!$elem.hasClass('slide'))
            return { souldSlide: false, moveDist: 0 };
        const $header = $(`#${this.id} header`);
        const $headerContent = $(`#${this.id} header>${sElement}>span`);
        const iHeaderPadding = parseFloat($header.css('padding-bottom')) + parseFloat($header.css('padding-top'));
        const iHeaderLength = $(`#${this.id} header>${sElement}`).height() || 0;
        const iContentLength = $headerContent.height() || 0;
        return {
            souldSlide: iContentLength - iHeaderLength > 25,
            moveDist: iContentLength + iHeaderPadding
        };
    };
    slideText = async (sElement) => {
        const { souldSlide: bShouldSlide, moveDist: iMoveDist } = this.shouldSlide(sElement);
        if (!bShouldSlide || this.headerInstances[sElement] !== false)
            return;
        this.headerInstances[sElement] = true;
        const $headerContent = $(`#${this.id} header>${sElement}>span`);
        await new Promise((resolve) => setTimeout(() => $headerContent.animate({ top: -iMoveDist }, iMoveDist * 5, 'linear', () => resolve()), 1500));
        await new Promise((resolve) => setTimeout(() => {
            $headerContent.css({ top: 0, opacity: 0 });
            $headerContent.animate({ opacity: 1 }, 500, 'linear', () => resolve());
        }, 1500));
        await new Promise((resolve) => setTimeout(() => resolve(), 1500));
        this.headerInstances[sElement] = false;
        this.slideText(sElement);
    };
    changeEvents = () => {
        this.slideText('h1');
        this.slideText('h2');
    };
    removeInitialStyles = () => $('#style-initial-intro').remove();
    start = async () => {
        const iTotalDelay = await this.animate([
            { selector: `#${this.id}`, delay: 750 },
            { selector: `#${this.id} header>h1, #${this.id} header>h2`, delay: 1000 },
            { selector: `#${this.id} .stripe:nth-of-type(1)`, delay: 600 },
            { selector: `#${this.id} .stripe:nth-of-type(2)`, delay: 450 },
            { selector: `#${this.id} .stripe:nth-of-type(3)`, delay: 450 }
        ]);
        setTimeout(() => {
            window.addEventListener('scroll resize orientationchange', this.changeEvents);
            this.changeEvents();
        }, iTotalDelay + 1500);
    };
}
export default {
    Intro: Intro
};
