<template>
    <div>
      <div class="loader-bg" :class="{ loading: show }" v-for="i in 2" :key="i">
        <div v-for="j in 3" :key="j"></div>
      </div>

      <div class="loader" v-if="show">
        <div v-for="k in 3" :key="k"></div>
      </div>

      <button type="button" v-if="showAppReset" class="btn" @click="resetApp()" id="loader-reset-btn">{{ text.viewer_reset_app }} ?</button>
    </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    show: Boolean,
    text: Object,
    showAppReset: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/_loader.scss";
</style>
