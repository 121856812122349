import axios from 'axios';

const state = {
  exhibition: null,
  exhibitions: []
};

const getters = {
  StateExhibition: state => state.exhibition,
  StateExhibitions: state => state.exhibitions
};

const actions = {
  /**
   * Fetch an exhibition
   *
   * @param {*} param0
   * @param {*} data
   */
  async GetExhibition({ commit }, data) {
    const oResponse = await axios.get(`https://arxafrica.net/getexhibition/${data.language}/${data.id}`);
    commit('setExhibition', oResponse?.data);
  },

  /**
   * Fetch all available exhibitions
   *
   * @param {*} param0
   * @param {*} language
   */
  async GetExhibitions({ commit }, language) {
    const oResponse = await axios.get(`https://arxafrica.net/getavailableexhibitions/${language}`);
    commit('setExhibitions', oResponse?.data || []);
  }
};

const mutations = {
  setExhibition(state, exhibition) {
    state.exhibition = exhibition;
  },
  setExhibitions(state, exhibitions) {
    state.exhibitions = exhibitions;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
