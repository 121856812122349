<template>
  <div id="body">
    <Header :onLanguageChange="fetchLanguageData" />

    <div id="app" ref="App">
      <Loader :show="!(localStorage && localStorage.a) && combinedLoader" :text="text" :showAppReset="showAppReset" />

      <Intro v-if="introVisible" :title="intro.title" :subtitle="intro.subtitle" :context="this" :hideDelay="1000" :text="text" :dataloaded="!!exhibition" />

      <div id="scanner-container" :class="{ 'visible': !mapbox.isVisible, 'camera-missing': !qr.initialized }">
        <video id="scanner" muted autoplay playsinline></video>

        <div class="scanner">
          <div class="scanner-bar-icon"><img src="../assets/scanner/001.svg" /></div>
          <div class="scanner-bar-icon"><img src="../assets/scanner/020.svg" /></div>
          <div class="scanner-bar-icon"><img src="../assets/scanner/002.svg" /></div>
          <div class="scanner-bar-icon"><img src="../assets/scanner/007.svg" /></div>

          <div class="scanner-bar-icon"><img src="../assets/scanner/002.svg" /></div>
          <div class="scanner-bar-icon"><img src="../assets/scanner/021.svg" /></div>
          <div class="scanner-bar-icon"><img src="../assets/scanner/028.svg" /></div>
          <div class="scanner-bar-icon"><img src="../assets/scanner/000.svg" /></div>

          <div class="scanner-bar"></div>
        </div>

        <div class="hint">
          <div class="hint-content">
            <p>{{ text.viewer_scanner_hint }}</p>
          </div>
        </div>
      </div>

      <button v-if="!bGpsOnly" id="map-button" :class="{ 'scanner-button': mapbox.isVisible }" @click="toggleMode()">
        <span v-if="!mapbox.isVisible"><img class="icon" src="../assets/icon_location.svg" /></span>
        <span v-else><img class="icon" src="../assets/icon_scanner.svg" /></span>
      </button>
      <div id="map" :class="{ visible: mapbox.isVisible }"></div>
      <div v-if="mapbox.isVisible" class="hint darken untouchable"><div class="hint-content"><p v-if="!bGpsOnly">{{ text.viewer_map_hint }}</p><p v-if="bGpsOnly">{{ text.viewer_map_hint_outdoor_only }}</p></div></div>

      <div class="overlay" :class="{ 'visible': showSlider || (introVisible && !!exhibition), 'xcamera': localStorage.m === 's', 'overlay-intro': introVisible && !!exhibition }"></div>

      <Artworks ref="Artworks" :context="this" :artworks="artworksSelected" :show="showSlider" :text="text" :currentLanguage="currentLanguage" :positionTick="mapbox.positionTick" />

      <div id="aframe" ref="Aframe" :class="{ visible: showAframe }">
        <CameraStream :active="showAframe" />
        <button class="btn hide-btn" @click="hideVideo()">
          <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 14" overflow="visible" xml:space="preserve">
            <path fill="#000" d="M14,1.4L12.6,0L7,5.6L1.4,0L0,1.4L5.6,7L0,12.6L1.4,14L7,8.4l5.6,5.6l1.4-1.4L8.4,7L14,1.4z"/>
          </svg>
        </button>
      </div>

      <InfoBox
        v-if="!mapbox.isLocating && !ignoreGpsMsg && localStorage.m === 'm' && !loaderVisible && !loaderCamera"
        :msg="text.viewer_gps_disabled"
        :btnText1="text.viewer_refresh_page"
        :btnText2="text.viewer_ignore"
        :fnOnClick1="reloadPage"
        :fnOnClick2="ignoreGpsError"
      />
      <InfoBox
        v-else-if="qr.error && localStorage.m === 's' && !loaderVisible && !loaderCamera"
        :msg="qr.error"
        :btnText1="text.viewer_refresh_page"
        :fnOnClick1="reloadPage"
      />

      <nav id="menu" :aria-hidden="showSlider" style="display:none">
        <ul>
          <MenuItem :doubleSided="true" :showBackside="localStorage.m !== 'm'" @click.native="toggleMode()" icon_front="qr" icon_back="location" />
        </ul>
      </nav>

      <div v-if="showGpsDebugPanel" style="position:fixed;top:5rem;left:0;background-color:rgba(0,0,0,.5);width:100%;max-height:3rem;pointer-events:none;color:#fff;z-index:9999;padding:0 1rem" v-text="JSON.stringify(localStorage.p)"></div>
    </div>
  </div>
</template>

<script>
import { oMetaDataTemplate, fnSetPageInformations, fnGetDocumentHidden, fnSetCssVariables } from '@/modules/globalFunctions.js';
import { mapGetters, mapActions } from 'vuex';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import Header from '@/components/Header.vue';
import Loader from '@/components/Loader.vue';
import Intro from '@/components/Intro.vue';
import InfoBox from '@/components/InfoBox.vue';
import Artworks from '@/components/Artworks.vue';
import MenuItem from '@/components/MenuItem.vue';
import CameraStream from '@/components/CameraStream.vue';
import QrScanner from 'qr-scanner';

const fnDistinct = aArray => {
  const aDistinct = [];
  aArray.forEach(elem => { if (aDistinct.indexOf(elem) === -1) aDistinct.push(elem); });
  return aDistinct;
};

const oTextTemplate = {
  viewer_gps_disabled: '',
  viewer_refresh_page: '',
  viewer_view_artwork: '',
  viewer_artwork_indoor: '',
  viewer_artwork_outdoor: '',
  viewer_artwork_outdoor_reached: '',
  viewer_share_title: '',
  viewer_share_text: '',
  viewer_intro_text: '',
  viewer_intro_button: '',
  viewer_translated_tag: '',
  viewer_ignore: '',
  viewer_scanner_hint: '',
  viewer_map_hint: '',
  viewer_map_hint_outdoor_only: '',
  viewer_camera_missing: '',
  viewer_reset_app: '',
  viewer_loading_model: '',
  viewer_android_version_warning: '',
  viewer_android_hint: '',
  viewer_ios_version_warning: '',
  viewer_replaced_link: '',
  viewer_use_chrome: ''
};

const oTemplates = {
  route: {
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: []
        }
      }
    },
    layer: {
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#009ee3',
        'line-width': 2,
        'line-dasharray': [2, 2],
      }
    }
  },
  points: {
    layer: {
      id: 'points',
      type: 'line',
      source: 'points',
      paint: {
        'line-color': '#fff',
        'line-opacity': 0.2,
        'line-width': 2
      }
    }
  }
};

const iTimeStart = new Date().getTime();

export default {
  name: 'AR',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    Header,
    Loader,
    Intro,
    InfoBox,
    Artworks,
    MenuItem,
    CameraStream
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'ar_viewer',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      loaderVisible: true,
      loaderCamera: false,
      loaderMap: false,
      counter: 0,
      exhibition: null,
      artworks: null,
      showSlider: false,
      showAframe: false,
      artworksSelected: [],
      groups: [],
      activeGroup: null,
      currentLanguage: null,

      bGpsOnly: false,

      // Map
      mapbox: {
        access_token: process.env.VUE_APP_MAP_ACCESS_TOKEN,
        center: [-74.5, 40],
        zoom: 11,
        map: null,
        geolocate: null,
        isVisible: false,
        isLocating: true,
        positionTick: 0
      },

      // QR reader
      qr: {
        error: null,
        initialized: false,
        scanner: null,
        camList: []
      },

      // Intro
      intro: {
        title: '',
        subtitle: ''
      },
      introVisible: true,

      combinedLoader: true,
      loaderTimer: iTimeStart,
      loaderTicker: null,

      // Message
      ignoreGpsMsg: false,

      // App reset button on loader screen
      showAppReset: false,
      showAppResetTimeout: 15000,

      initScannerAttempts: 0,
      initScannerAttemptsMax: 5,

      // Debug
      showLine: false, // GPS connector
      showCamSelector: false,
      preventClickByIndoor: true,
      ignoreBounds: true, // Set to true to avoid GPS positioning issues. If its set to false and the user is out of range, the position of the user might not be fetchted correctly.
      ignoreZoomMinMax: false,
      drawRanges: false,
      preventInactiveRedirect: false,
      ignoreGpsRange: false,
      showGpsDebugPanel: false
    };
  },
  watch: {
    $route() {
      this.init();
    },

    loaderVisible() {
      this.loaderChanged(this.loaderVisible || this.loaderCamera || this.introVisible);
    },

    loaderCamera() {
      this.loaderChanged(this.loaderVisible || this.loaderCamera || this.introVisible);
    },

    introVisible() {
      this.loaderChanged(this.loaderVisible || this.loaderCamera || this.introVisible);
    }
  },
  mounted: async function () {
    await fnSetPageInformations(this, oTextTemplate);
    await this.init();
  },
  computed: {
    ...mapGetters({ Exhibition: 'StateExhibition' })
  },
  methods: {
    ...mapActions(['GetExhibition']),

    /***********************
     *    G e n e r a l    *
     ***********************/

    /**
     * Changed event for loader
     *
     * @param {boolean} bNewState
     */
    loaderChanged(bNewState) {
      const iTimeStamp = new Date().getTime();

      if (this.combinedLoader === bNewState && this.loaderTimer !== 0) return;

      if (bNewState) {
        this.loaderTimer = iTimeStamp;

        // Show app reset button after configurated delay
        this.showAppReset = false;
        if (this.loaderTicker !== null) clearInterval(this.loaderTicker);

        this.loaderTicker = setInterval(() => {
          this.showAppReset = true;

          clearInterval(this.loaderTicker);
          this.loaderTicker = null;
        }, this.showAppResetTimeout);
      } else {
        console.log(`Loaded for ${iTimeStamp - this.loaderTimer} ms`);

        // Hide app reset
        this.showAppReset = false;
        if (this.loaderTicker !== null) clearInterval(this.loaderTicker);
        this.loaderTicker = null;
      }

      this.combinedLoader = bNewState;

      if (!this.introVisible && this.localStorage.m === 'm' && !this.showSlider && this.geolocate) this.geolocate.trigger(); // Turn on geolocating
    },

    async fetchLanguageData() {
      this.fetchLocalStorage();
      await fnSetPageInformations(this, oTextTemplate);
    },

    /**
     * Initialization function
     */
    async init() {
      this.fetchLocalStorage();

      this.loaderVisible = true;

      // Unload scanner
      this.qr.scanner?.stop();
      this.qr.scanner?.destroy();
      this.qr.scanner = null;

      // Dev mode
      const oUrlParams = new URLSearchParams(location.search);
      if (oUrlParams.has('dev')) {
        this.ignoreZoomMinMax = true;
        this.preventInactiveRedirect = true;
        this.ignoreGpsRange = true;
      }

      // Get entrypoint and normalize url
      const oHashData = this.fnGetDataFromHash(location);
      const sId = oHashData.a ? oHashData.a : this.localStorage.r?.a;
      const sExhibition = oHashData.e ? oHashData.e : this.localStorage.r?.e;
      if (sExhibition) this.exhibitionId = sExhibition;

      // Check if intro should be skipped
      const iExhibitionId = parseInt(this.exhibitionId || '0');
      this.introVisible = this.localStorage.i.indexOf(iExhibitionId) === -1;

      if (!this.currentLanguage) this.currentLanguage = this.localStorage.L;

      if (!this.exhibitionId) {
        this.$router.push('/list');
        return;
      }

      window.addEventListener('resize', fnSetCssVariables());
      window.addEventListener('orientationchange', fnSetCssVariables());
      fnSetCssVariables();

      await this.GetExhibition({ id: this.exhibitionId, language: this.currentLanguage });
      this.exhibition = this.Exhibition || null;

      if (this.exhibition.inactive && !this.preventInactiveRedirect) {
        this.$router.push(`/list?inactive=${this.exhibition.inactive}`);
        return;
      }

      document.addEventListener('visibilitychange', () => {

        const oHidden = fnGetDocumentHidden();

        this.qr.scanner?.stop();
        this.qr.scanner?.destroy();
        this.qr.scanner = null;

        if (document[oHidden.state] === 'visible' && this.localStorage.m !== 'm' && !this.showSlider) setTimeout(() => this.initScanner(), 0);
      });

      // Set exhibition data
      this.intro.title = this.exhibition.name || '';
      this.intro.subtitle = [
        this.exhibition.city || '',
        this.exhibition.location || ''
      ]
        .map(sPart => sPart.trim())
        .filter(sPart => sPart.length)
        .join(' ');

      this.artworks = (this.exhibition?.artworks || [])
        .filter(oArtwork => oArtwork.longitude !== null && oArtwork.latitude !== null)
        .map(oArtwork => {
          if (!oArtwork.range) oArtwork.range = 15; // Default range in meters
          return oArtwork;
        });

      this.bGpsOnly = this.artworks.every(oArtwork => oArtwork.locationtype === 'outdoor');
      if (this.bGpsOnly) this.updateLocalStorage({ m: 'm' }); // Set mode to map

      this.registerVimeoPreviews(this.artworks.map(oArtwork => oArtwork.works_id));

      const aUniqueArray = fnDistinct(this.artworks.map(oArtwork => JSON.stringify([oArtwork.longitude, oArtwork.latitude])));

      this.artworks.forEach(oArtwork => {
        const iIndex = aUniqueArray.indexOf(JSON.stringify([oArtwork.longitude, oArtwork.latitude]));

        if (typeof this.groups[iIndex] !== 'object') this.groups[iIndex] = {
          latitude: oArtwork.latitude,
          longitude: oArtwork.longitude,
          artworks: [],
          distance: null,
          type: oArtwork.locationtype,
          ranges: [oArtwork.range]
        };

        if (iIndex !== -1) {
          this.groups[iIndex].artworks.push(oArtwork.works_id.id);
          if (this.groups[iIndex].type !== 'mixed' && this.groups[iIndex].type !== oArtwork.locationtype) this.groups[iIndex].type = 'mixed';
          this.groups[iIndex].ranges.push(oArtwork.range);
        }
      });

      // Remove detail duplicates
      this.groups.forEach((oArtwork, i) => {
        const aRanges = fnDistinct(oArtwork.ranges);
        if (aRanges.length !== oArtwork.ranges.length) this.groups[i].ranges = aRanges;
      });

      // Normalize url, remove url hash
      if (sId) {
        // console.log('ID requested via URL:', sId);
        history.replaceState('', document.title, (window.location.pathname || `/${sExhibition}`) + window.location.search);
      }

      const { a: sReturnToArtwork, e: sReturnToExhibition } = this.localStorage.r || {};
      const bMatchesExhibition = sReturnToExhibition && sReturnToExhibition === sExhibition;
      if (bMatchesExhibition || sId) {
        this.activateArtworkGroup(sReturnToArtwork || sId);
        if (bMatchesExhibition) this.updateLocalStorage({ r: null });
      }

      if (!this.intro.visible) await this.toggleMode(this.localStorage.m || 's');
      if (this.localStorage.f) this.updateLocalStorage({ f: false });
    },

    /**
     * Activate an artwork group and stop QR scanner
     *
     * @param {string} sArtworkId
     */
    activateArtworkGroup(sArtworkId) {
      let iGroupId = null;
      const oRequestedGroup = this.groups.filter((oGroup, iId) => {
        const bFulfilled = oGroup.artworks.indexOf(parseInt(sArtworkId)) !== -1;
        if (bFulfilled) iGroupId = iId;
        return bFulfilled;
      }).shift();

      if (!oRequestedGroup || (this.activeGroup !== null && (iGroupId === null || iGroupId === this.activeGroup))) return;
      this.activeGroup = iGroupId;

      const aArtworks = oRequestedGroup?.artworks || [];
      this.artworksSelected = [];
      this.fetchArtworks(aArtworks);

      const bShowSlides = !!aArtworks.length;
      this.showSlider = bShowSlides;
      this.$refs.Artworks.bSliderVisible = bShowSlides;

      // Set correct slider element
      setTimeout(() => {
        const iIndex = aArtworks.map(i => i.toString()).indexOf(sArtworkId);
        this.$refs.Artworks.moveToSlide(iIndex !== -1 ? iIndex : 0);
      }, 25);

      this.updateLocalStorage({ r: bShowSlides ? { a: sArtworkId, e: this.exhibitionId } : null }); // Set return to artwork

      setTimeout(() => document.querySelector('meta[name=theme-color]')?.setAttribute('content', '#000'), 100);

      this.qr.scanner?.stop();
      this.qr.scanner?.destroy();
      this.qr.scanner = null;
    },

    /**
     * Fetch artwork data from exhbition and store selected IDs in this.artworksSelected
     *
     * @param {string[]} aArtworkIds Requested artwork IDs
     * @param {'indoor'|'outdoor'|null} sFilterType Optional artwork type filter
     * @returns {boolean} Array filled
     */
    fetchArtworks(aArtworkIds, sFilterType = null) {
      const aIds = aArtworkIds.map(sId => parseInt(sId)); // Array items to int conversion
      const aArtworks = this.exhibition?.artworks.filter(oArtwork => aIds.indexOf(oArtwork.works_id.id) !== -1 && (!sFilterType || sFilterType === oArtwork.locationtype)) || [];
      this.artworksSelected = aArtworks;
      return !!aArtworks.length;
    },

    /**
     * Hide artwork slider via class assignment.
     * Can reinitiate the QR scanner
     */
    hideArtworks() {
      this.activeGroup = null;
      this.$refs.Artworks.bSliderVisible = false;
      this.showSlider = false;
      Array.from(document.querySelectorAll('#map .custom-marker-interact.selected')).forEach(oMarker => oMarker.classList.remove('selected'));
      document.querySelector('meta[name=theme-color]')?.setAttribute('content', this.localStorage.m === 's' ? '#e94d18' : '#000');
      if (this.localStorage.m === 's') this.initScanner(false);
      if (this.localStorage.m === 'm' && !this.showSlider && !this.geolocate?.options?.showUserLocation) this.geolocate.trigger(); // Turn on geolocating
    },

    /**
     * Removes the Aframe Iframe.
     */
    async hideVideo() {
      this.showAframe = false;
      this.$refs.Aframe.getElementsByTagName('iframe')[0].remove();
    },

    /**
     * Toggles visible items depending on which mode is choosen
     *
     * @param {'i'|'s'|'m'|null} sMode
     */
    async toggleMode(sMode = null) {
      this.loaderVisible = true;

      document.querySelector('meta[name=theme-color]')?.setAttribute('content', '#000');

      const { m: sCurrentMode } = this.localStorage;

      // Toggle content
      const bToScanner = (sCurrentMode === 'm' && !sMode) || sMode === 's';
      const bToMap = (sCurrentMode === 's' && !sMode) || sMode === 'm' || sMode === 'i';

      this.updateLocalStorage(bToScanner || bToMap ? { m: bToScanner ? 's' : 'm' } : null);

      // Show loader for at least one second
      const aPromise = [new Promise(resolve => setTimeout(() => resolve(), 1000))];
      if (bToMap && !this.loaderMap) aPromise.push(this.loadMap());
      await Promise.all(aPromise);

      this[bToMap ? 'mapShow' : 'mapHide']();
      this.loaderVisible = false;

      setTimeout(() => document.querySelector('meta[name=theme-color]')?.setAttribute('content', (this.localStorage.m === 's' && !this.showSlider) ? '#e94d18' : '#000'), 600);

      if (!this.showSlider) {
        if (this.localStorage.m === 'm') {
          this.qr.scanner?.stop();
        } else {
          this.qr.scanner?.destroy();
          this.qr.scanner = null;
          this.initScanner();
        }
      }
    },

    /**
     * Refresh window location
     */
    reloadPage() {
      window.location.reload();
    },

    ignoreGpsError() {
      this.ignoreGpsMsg = true;
    },


    /***********************
     *        M a p        *
     ***********************/

    /**
     * Initialize MapBox component
     */
    async loadMap() {
      this.loaderMap = true;

      const aVisited = this.localStorage.v
        .filter(oVisited => oVisited.e === this.exhibitionId)
        .map(oVisited => oVisited.a);

      /**
       * Get SW and NE coordinates in an array.
       *
       * @param {{
       *   longitude: number;
       *   latitude: number;
       * }[]} aArtworks
       */
      const fnGetBounds = (aArtworks, bTransform = true) => {
        // Add the current coordinates to the bounds array, so we can fit the map extent to the bounds of all markers
        const aBoundingBoxCoords = aArtworks.map(oArtwork => [oArtwork.longitude, oArtwork.latitude]);
        if (!aBoundingBoxCoords.length) return null;
        const aPolygons = turf.bboxPolygon(turf.bbox(turf.lineString(aBoundingBoxCoords)));
        const aCoordinates = (bTransform ? turf.transformScale(aPolygons, 50) : aPolygons).geometry.coordinates.shift();
        return [
          [aCoordinates[0][0], aCoordinates[0][1]], // Southwest coordinates
          [aCoordinates[2][0], aCoordinates[2][1]]  // Northeast coordinates
        ];
      };

      /**
       * Create marker DOM node
       *
       * @param {string} sId Group ID
       * @param {number} iLng Longitude
       * @param {number} iLat Latitude
       * @param {any[]} aArtworks List of artwork IDs
       * @param {'indoor'|'outdoor'|'mixed'} sLocationType Type of the marker
       */
      const fnCreateMarker = (sId, iLng, iLat, aArtworks, sLocationType) => {
        const iGroupSize = aArtworks.length;
        const bVisitedAny = aArtworks.some(iId => aVisited.indexOf(iId.toString()) !== -1);

        const oMarker = document.createElement('div');
        oMarker.classList.add('mapboxgl-marker', 'custom-marker-interact', 'mapboxgl-marker-anchor-center');
        if (bVisitedAny) oMarker.classList.add('visited');
        oMarker.dataset.id = sId;
        oMarker.dataset.longitude = iLng;
        oMarker.dataset.latitude = iLat;
        oMarker.dataset.group = aArtworks.join();
        oMarker.dataset.type = sLocationType;

        oMarker.insertAdjacentHTML('beforeend', `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.789 38.74" preserveAspectRatio="none"><path fill="currentColor" stroke="#fff" stroke-width="2" d="M39.477-83.332A12.359,12.359,0,0,0,27.082-70.937c0,6.872,12.395,23.661,12.395,23.661S51.871-64.065,51.871-70.937A12.359,12.359,0,0,0,39.477-83.332Zm0,18.027a5.58,5.58,0,0,1-5.634-5.634,5.58,5.58,0,0,1,5.634-5.634,5.58,5.58,0,0,1,5.634,5.634A5.58,5.58,0,0,1,39.477-65.305Z" transform="translate(-26.082 84.332)"></path></svg>`);

        // Add group label to marker
        if (iGroupSize > 1) {
          const oGroupSizeLabel = document.createElement('div');
          oGroupSizeLabel.classList.add('group-label');
          oGroupSizeLabel.textContent = iGroupSize.toString();

          oMarker.appendChild(oGroupSizeLabel);
        }

        return oMarker;
      };

      /**
       * Marker onClick event.
       * Action is prevented if location type of the Marker is 'indoor'
       *
       * @param {any} oEvent Click event
       */
      const fnMarkerOnClick = async oEvent => {
        oEvent.stopPropagation();

        const oTarget = oEvent.currentTarget;
        const {
          id: sId,
          group: sGroup,
          longitude: iLng,
          latitude: iLat,
          type: sLocationType
        } = oTarget.dataset || {};
        const aArtworkIds = sGroup?.split(',') || [];

        const bReopen = this.showSlider;
        this.hideArtworks();

        // Prevent click event if location is indoor
        if (this.preventClickByIndoor && sLocationType === 'indoor') return;

        this.activeGroup = parseInt(sId);

        // Change selected marker
        Array.from(document.querySelectorAll('.custom-marker-interact.selected')).forEach(oElem => oElem.classList.remove('selected'));
        oTarget.classList.add('selected');

        if (bReopen) await new Promise(resolve => setTimeout(() => resolve(), 450));

        this.showSlider = this.fetchArtworks(aArtworkIds, 'outdoor');

        // Draw connector between current position and selected marker
        if (iLng !== undefined && iLat !== undefined && this.showLine) this.mapbox.map.getSource('route').setData({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [
              this.localStorage.p,
              [iLng, iLat]
            ]
          }
        });
      };

      /**
       * Geolocation event.
       * Fires when the current location changes
       *
       * @param {any} oEvent Geolocate event
       */
      const fnOnGeolocate = oEvent => {
        this.mapbox.isLocating = true;
        const aCoords = [oEvent.coords.longitude, oEvent.coords.latitude]
        this.updateLocalStorage({ p: aCoords });

        this.mapbox.positionTick++;

        /**
         * Refresh the distance to each artwork group.
         * Each group represents a marker on the map
         */
        this.groups.forEach((oGroup, i) => {
          this.groups[i].distance = Math.floor(turf.distance(
            aCoords,
            [oGroup.longitude, oGroup.latitude],
            { units: 'kilometers' }
          ) * 1000);

          // Refresh the connector between the current location and the marker
          if (this.activeGroup === i && this.showLine) this.mapbox.map.getSource('route').setData({
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: [
                aCoords,
                [oGroup.longitude, oGroup.latitude]
              ]
            }
          });
        });
      };

      /**
       * Maps onLoad event
       */
      const fnMapLoad = () => {
        // Remove MapBox logo
        document.querySelector('.mapboxgl-ctrl-logo')?.parentElement?.remove();

        const oMarkerData = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        };

        /**
         * Set the distance to each artwork group.
         * Each artwork group is represented by a marker on the map
         */
        this.groups.forEach((oGroup, sId) => {
          const oMarker = fnCreateMarker(sId, oGroup.longitude, oGroup.latitude, oGroup.artworks, oGroup.type);

          if (this.drawRanges) oGroup.ranges.forEach(iRange => oMarkerData.data.features.push(turf.circle(
            [oGroup.longitude, oGroup.latitude],
            iRange / 1000, // Range in kilometers
            { steps: 64, units: 'kilometers' }
          )));

          // Apply click event to marker
          oMarker.addEventListener('click', fnMarkerOnClick);

          new mapboxgl.Marker(oMarker)
              .setLngLat([oGroup.longitude, oGroup.latitude])
              .addTo(this.mapbox.map);
        });

        // Add connectors to map as layers
        this.mapbox.map.addSource('points', oMarkerData);
        if (this.showLine) {
          this.mapbox.map.addSource('route', oTemplates.route.source);
          this.mapbox.map.addLayer(oTemplates.route.layer);
        }
        this.mapbox.map.addLayer(oTemplates.points.layer);

        // Turn on geolocating
        if (!this.showSlider && !this.introVisible) {
          this.geolocate.trigger();
        }

        this.loaderMap = false;
      };

      this.fetchLocalStorage();

      if (this.artworks.length) this.updateLocalStorage({ p: [this.artworks[0].longitude, this.artworks[0].latitude] });

      const aBounds = fnGetBounds(this.artworks) || []; // Set the map's geographical boundaries.
      const aBoundsFit = fnGetBounds(this.artworks, false) || [];
      const oMapConfig = {
        ...{
          container: 'map',
          style: 'mapbox://styles/mapbox/dark-v10', // style URL
          center: this.localStorage.p,
          zoom: this.mapbox.zoom,
          attributionControl: false
        },
        ...(this.ignoreZoomMinMax ? {} : {
          minZoom: 11,
          maxZoom: 20
        }),
        ...(this.ignoreBounds || aBounds.length < 2 ? {} : {
          maxBounds: aBounds
        })
      };

      const oGeolocateControlConfig = {
        fitBoundsOptions: {
          maxZoom: 17
        },
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: false
      };

      // Initialize MapBox map with a timeout of 7,5s
      return Promise.race([
        new Promise(resolve => setTimeout(() => {
          this.loaderMap = false;
          resolve(false);
        }, 7500)),
        new Promise(resolve => {
          try {
            mapboxgl.accessToken = this.mapbox.access_token;
            this.mapbox.map = new mapboxgl.Map(oMapConfig);
            // this.mapbox.map.on('click', () => this.hideArtworks());

            // Add controls
            this.mapbox.map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

            // Add locator
            this.geolocate = new mapboxgl.GeolocateControl(oGeolocateControlConfig);
            this.geolocate.on('geolocate', fnOnGeolocate);
            this.geolocate.on('error', () => this.mapbox.isLocating = false);
            this.mapbox.map.addControl(this.geolocate, 'bottom-left');

            this.mapbox.map.on('load', () => {
              fnMapLoad();
              if (aBoundsFit.length >= 2) this.mapbox.map.fitBounds(aBoundsFit); // Fit map to bounding box
              resolve(true);
            });
          } catch (oError) {
            console.log('map error', oError);
            this.Map = false;
            resolve(false);
          }
        })
      ]);
    },

    /**
     * Show map
     */
    mapShow() {
      this.mapbox.isVisible = true;
    },

    /**
     * Remove map
     */
    mapHide() {
      try {
        this.mapbox.map?.remove();
      } catch (_oError) { console.log(); }
      this.mapbox.isVisible = false;
    },


    /***********************
     *  Q R - R e a d e r  *
     ***********************/

    /**
     * Initialize QR scanner
     */
    async initScanner(loading = true) {
      try {
        document.querySelectorAll('.scan-region-highlight').forEach(oElem => oElem.remove());
        this.loaderCamera = loading;

        const oScannerParameters = {
          // calculateScanRegion: ???,
          returnDetailedScanResult: true,
          highlightScanRegion: false
        };

        this.qr.scanner = new QrScanner(
          document.querySelector('#scanner'),
          result => {
            if (this.localStorage.m === 'm' || !result?.data) return;

            // Check if last code is different to new code or delay is passed
            let oUrl = null;
            try {
              oUrl = new URL(result.data);
            } catch (error) {
              oUrl = null;
            }
            if (!oUrl) return;

            // Extract data from URL and activate corresponding artwork group
            const { a: sId, e: sExhibition } = this.fnGetDataFromHash(oUrl);
            if (sId && sExhibition && sExhibition === this.exhibitionId) this.activateArtworkGroup(sId);
          },
          oScannerParameters
        );
        this.qr.scanner.setInversionMode('original'); // 'original'|'invert'|'both'

        await this.qr.scanner.start()
          .then(() => this.qr.initialized = true)
          .catch(sReason => {
            this.qr.error = sReason;
            if (sReason === 'Camera not found.') this.qr.error = this.text.viewer_camera_missing;
          });

        this.initScannerAttempts = 0;
        this.loaderCamera = false;
      } catch (_oError) {
        // Reinitiate QR scanner if an error occures
        console.log(_oError);
        this.qr.scanner?.stop();
        this.qr.scanner?.destroy();
        this.qr.scanner = null;

        if (this.initScannerAttempts < this.initScannerAttemptsMax) {
          console.log('Retry qr initialization');
          this.initScannerAttempts = this.initScannerAttempts + 1;
          this.initScanner();
        }
      }
    },

    /**
     * Clear error message
     */
    clearQrError() {
      this.qr.error = null;
    }
  }
}
</script>

<style lang="scss">
@import "./../scss/_global.scss";
@import "./../scss/_ar_viewer.scss";
</style>
