import Vue from 'vue';
import VueMeta from 'vue-meta';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMatomo from 'vue-matomo';

Vue.config.productionTip = false;

/**
 * VueMeta API Reference:
 * https://vue-meta.nuxtjs.org/api/
 */
Vue.use(VueMeta, {
  keyName: 'head'
});

Vue.config.ignoredElements = [
  'a-scene',
  'a-entity',
  'a-camera',
  'a-box',
  'a-sky',
  'a-sphere',
  'a-cylinder',
  'a-plane',
  'a-marker'
];

if ([
  'localhost',
  'arxafrica.manx',
  'artists.arxafrica.manx',
  'exhibition.arxafrica.manx'
].indexOf(location.hostname) === -1) Vue.use(VueMatomo, {
  host: 'https://matomo.arxafrica.net/',
  siteId: 3,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: true,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});

// Get language
const aLanguages = ['en', 'de', 'fr'];
let sLanguage = null;
navigator.languages.forEach(sKeyLong => {
  if (sLanguage) return;
  const sKey = sKeyLong.split('-').shift();
  if (aLanguages.indexOf(sKey) !== -1) sLanguage = sKey;
});

// Get device type
const bIsApple = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'iPhone', 'iPad', 'iPod'].indexOf(window.navigator.platform) !== -1;

const oLocalStorageDefault = {
  m: 'i',
  f: true,
  p: [-74.5, 40],
  l: [],
  v: [],
  L: sLanguage || 'en',
  i: [],
  r: null,
  a: false
};

let oVimeoPreviews = {};

const oUrlParams = new URLSearchParams(location.search);
if (!oUrlParams.has('dev')) {
  console.log = () => { };
  console.warn = () => { };
  console.error = () => { };
  console.clear();
}

// Global data
Vue.mixin({
  /**
   * @returns {{
   *   localStorage: {
   *     m: 'i' | 's' | 'm';
   *     f: boolean;
   *     p: [number, number];
   *     l: {
   *       a: string | null;
   *       e: string | null;
   *     }[];
   *     v: {
   *       a: string | null;
   *       e: string | null;
   *     }[];
   *     L: 'de' | 'en' | 'fr';
   *     i: number[];
   *   };
   *   exhibitionId: string | null;
   * }}
   */
  data() {
    return {
      // LocalStorage elements
      localStorage: oLocalStorageDefault,
      exhibitionId: this.$route.params?.id || null,
      isApple: bIsApple,
      languages: aLanguages,
      blockArButton: false
    };
  },
  methods: {
    /**
     * Get local storage data
     */
    fetchLocalStorage() {
      this.localStorage = {
        ...this.localStorage,
        ...JSON.parse(localStorage.getItem('viewer') || '{}')
      };
      for (const sKey in this.localStorage) this[sKey] = this.localStorage[sKey];
    },

    /**
     * Update local storage data and optionally change data at the same time
     *
     * m - Mode | i intro | s scanner | m map
     * f - First load
     * p - Position
     * l - Likes
     * v - Visited
     * L - Language
     * i - Skip intro for given exhibition IDs
     * r - Return to artwork
     * a - User state in AR viewer
     * @param {{
     *   m: 'i' | 's' | 'm';
     *   f: boolean;
     *   p: [number, number];
     *   l: {
     *     a: string | null;
     *     e: string | null;
     *   }[];
     *   v: {
     *     a: string | null;
     *     e: string | null;
     *   }[];
     *   L: 'de' | 'en' | 'fr';
     *   i: number[];
     *   r: string | null;
     *   a: false;
     * }} oData
     */
    updateLocalStorage(oData = null) {
      this.fetchLocalStorage();
      if (oData) this.localStorage = {
        ...this.localStorage,
        ...oData
      };
      localStorage.setItem('viewer', JSON.stringify(this.localStorage));
    },

    /**
     * Get ID from url hash
     * @param {URL} oUrl
     */
    fnGetDataFromHash(oUrl) {
      // URL pattern https://exhibition.arxafrica.net/#1234.5678

      // Check url hash for base64 string
      const { groups: oHashGroup } = oUrl.hash.match(/#(?<hash>[a-z0-9/+=]+)/i) || {};
      const { hash: sUrlHash } = oHashGroup || {};
      // Check base64 string for type and ID
      const { groups: oDataGroup } = decodeURIComponent(escape(window.atob(sUrlHash || '')))?.match(/(?<e>[0-9]+)\.(?<a>[0-9]+)/i) || {};
      const { e: sExhibition, a: sArtwork } = oDataGroup || {};

      return {
        a: sArtwork || null,
        e: sExhibition || null
      };
    },

    /**
     * @param {{
     *   id: number;
     *   type: 'model'|'image'|'video';
     *   vimeo_id: number|null;
     * }[]} aArtworks
     */
    async registerVimeoPreviews(aArtworks) {
      const aVimeoVideos = await Promise.all(
        aArtworks
          .filter(oArtwork => oArtwork.type === 'video' && oArtwork.vimeo_id)
          .map(oArtwork => this.getVimeoPreview(oArtwork.id.toString(), oArtwork.vimeo_id.toString(), 1200))
      );
      oVimeoPreviews = {};
      aVimeoVideos
        .filter(oData => oData.link)
        .forEach(oData => oVimeoPreviews[`_${oData.id}`] = oData.link.replace('?r=pad', ''));
    },

    /**
     * @param {string} sArtworkId
     * @returns {string|null}
     */
    getArtworkPreview(sArtworkId) {
      return oVimeoPreviews[`_${sArtworkId}`] || null;
    },

    /**
     * @param {string} sId
     * @param {string} sVimeoId
     * @param {number} iMinWidth
     * @returns
     */
    async getVimeoPreview(sId, sVimeoId, iMinWidth = 0) {
      const oReturn = {
        id: sId,
        link: null
      };
      if (!sVimeoId) return oReturn;

      const oResponse = await axios({
        method: 'get',
        url: `${process.env.VUE_APP_ARTISTS_API}/videos/${sVimeoId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      oReturn.link = (oResponse?.data?.pictures?.sizes || [])
        .filter(oSize => oSize.width > iMinWidth)
        .sort((a, b) => a.width - b.width)
        .shift()?.link;

      return oReturn;
    },

    resetApp() {
      // Store data
      const { p, l, v, L } = this.localStorage || {};

      // Store texts and metas
      const oStorage = {};
      Object.keys(localStorage)
        .filter(sKey => sKey.indexOf('storedMeta') !== -1 || sKey.indexOf('storedTexts') !== -1)
        .forEach(sKey => oStorage[sKey] = localStorage.getItem(sKey))

      localStorage.clear();

      // Set stored data
      this.updateLocalStorage({
        ...oLocalStorageDefault,
        p: p,
        l: l,
        v: v,
        L: L
      });

      // Set stored texts and metas
      Object.keys(oStorage).forEach(sKey => localStorage.setItem(sKey, oStorage[sKey]));

      location.reload();
    }
  }
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
