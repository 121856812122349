import axios from 'axios';
import $ from 'jquery';
export const oMetaDataTemplate = {
    page: '',
    meta_description: '',
    tab_title: 'Arx Africa',
    meta_keywords: '',
    meta_robots_index: 'noindex',
    meta_robots_follow: 'nofollow',
    meta_robots_max_snippet: '',
    meta_robots_max_video_preview: '',
    meta_robots_max_image_preview: '',
    meta_og_title: '',
    meta_og_image: '',
    meta_og_type: '',
    meta_og_url: '',
    meta_og_site_name: '',
    meta_og_description: '',
    meta_twitter_card: '',
    meta_twitter_title: '',
    meta_twitter_description: '',
    meta_twitter_image: '',
    meta_twitter_site: '',
    meta_twitter_creator: ''
};
export const oRoles = {
    artist: '4dd62785-aaa8-40a6-b6ee-85f9c8834734',
    exhibitor: 'eaae4e04-dd0d-4254-a9ef-e833f947941c'
};
export const oMessageBoxDataTemplate = {
    show: false,
    type: 'initial',
    text: '',
    classes: [],
    timestamp: 0
};
export const fnGetTexts = async (sLanguage, sTextType, aFields = ['*'], aFilters = []) => {
    if (!aFields.length)
        return {};
    const sFieldList = aFields.join(',');
    const sFilterList = aFilters.length ? aFilters.join('&') : '';
    const { data: texts } = await axios.post('https://arxafrica.net/lib/php/data/getTexts.php', {
        language: sLanguage,
        texts: sTextType,
        fields: sFieldList,
        filters: sFilterList
    }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    if (Array.isArray(texts)) {
        texts.forEach(oItem => {
            delete oItem.id;
            delete oItem.translations;
        });
    }
    else if (texts) {
        delete texts.id;
        delete texts.translations;
    }
    return texts;
};
export const fnGetMetaData = async (sPageName, sLanguage) => {
    const oData = await fnGetTexts(sLanguage, 'seo', ['*', 'translations.*'], [`filter[page]=${sPageName}`]);
    const oMetaDataMaintained = oData?.shift() || {};
    const oMetaData = {};
    for (let sKey in oMetaDataTemplate)
        oMetaData[sKey] = oMetaDataMaintained[sKey] || oMetaDataTemplate[sKey];
    return {
        title: oMetaData.tab_title,
        meta: [
            { name: 'description', content: oMetaData.meta_description },
            { name: 'keywords', content: oMetaData.meta_keywords },
            {
                name: 'robots', content: [
                    oMetaData.meta_robots_index,
                    oMetaData.meta_robots_follow,
                    oMetaData.meta_robots_max_snippet ? `max-snippet:[${oMetaData.meta_robots_max_snippet}]` : '',
                    oMetaData.meta_robots_max_video_preview ? `max-video-preview:[${oMetaData.meta_robots_max_video_preview}]` : '',
                    oMetaData.meta_robots_max_image_preview ? `max-image-preview:[${oMetaData.meta_robots_max_image_preview}]` : ''
                ].filter(sValue => !!sValue).join(', ')
            },
            { property: 'og:title', content: oMetaData.meta_og_title },
            { property: 'og:image', content: oMetaData.meta_og_image ? `https://arxafrica.net/assets/${oMetaData.meta_og_image}/thumbnail` : '' },
            { property: 'og:type', content: oMetaData.meta_og_type },
            { property: 'og:url', content: oMetaData.meta_og_url },
            { property: 'og:site_name', content: oMetaData.meta_og_site_name },
            { property: 'og:description', content: oMetaData.meta_og_description },
            { name: 'twitter:card', content: oMetaData.meta_twitter_card },
            { name: 'twitter:title', content: oMetaData.meta_twitter_title },
            { name: 'twitter:description', content: oMetaData.meta_twitter_description },
            { name: 'twitter:image', content: oMetaData.meta_twitter_image ? `https://arxafrica.net/assets/${oMetaData.meta_twitter_image}/thumbnail` : '' },
            { name: 'twitter:site', content: oMetaData.meta_twitter_site },
            { name: 'twitter:creator', content: oMetaData.meta_twitter_creator }
        ]
    };
};
export const fnRunSimultaneously = async (aFn, sLabel) => {
    const iTimeStamp = Date.now();
    const aData = await Promise.all(aFn.map((fn, i) => new Promise((resolve) => {
        const iTime = Date.now();
        fn().then(() => resolve({
            index: i,
            duration: Date.now() - iTime
        }));
    })));
    const iTotal = Date.now() - iTimeStamp;
    const sDataOutput = aData.map(oData => `Fn ${oData.index + 1} | ${oData.duration}ms`).join('\n');
    const sPrefix = sLabel ? `${sLabel}\n` : '';
    console.log(`%c${sPrefix}%cTotal request time: ${iTotal}ms%c\n\n${sDataOutput}`, 'color:#A8A8A8;font-weight:bold', 'color:#707070;font-weight:bold', '');
};
export const fnCloseMenu = () => {
    const $menuButton = $('#body>header .menu-button');
    $menuButton.removeClass('opened');
    $menuButton.attr('aria-expanded', 'false');
    $('body').removeClass('block-scroll-s');
};
export const fnGetStoredTexts = (oTextTemplate, sLanguage) => {
    const oTexts = {};
    const sLanguageCamelCase = sLanguage.charAt(0).toUpperCase() + sLanguage.slice(1);
    const sStorageKey = `storedTexts${sLanguageCamelCase}`;
    const sJsonStoredTexts = localStorage.getItem(sStorageKey) || '';
    if (!sJsonStoredTexts)
        return oTexts;
    const oStoredTexts = JSON.parse(sJsonStoredTexts);
    for (let sKey in oTextTemplate) {
        if (!oStoredTexts[sKey])
            continue;
        oTexts[sKey] = oStoredTexts[sKey];
    }
    return oTexts;
};
export const fnUpdateStoredTexts = (oTexts, sLanguage) => {
    const sLanguageCamelCase = sLanguage.charAt(0).toUpperCase() + sLanguage.slice(1);
    const sStorageKey = `storedTexts${sLanguageCamelCase}`;
    const oStoredTexts = JSON.parse(localStorage.getItem(sStorageKey) || '{}');
    localStorage.setItem(sStorageKey, JSON.stringify({
        ...oStoredTexts,
        ...oTexts
    }));
};
export const fnGetStoredMetaData = (sPageName, sLanguage) => {
    const fnUpperStart = (sWord) => sWord.charAt(0).toUpperCase() + (sWord.length > 1 ? sWord.slice(1) : '');
    const sPageNameCase = sPageName.split('_').map(sPart => fnUpperStart(sPart)).join('');
    const sLanguageCamelCase = fnUpperStart(sLanguage);
    const sStorageKey = `storedMeta${sLanguageCamelCase}${sPageNameCase}`;
    const sJsonStoredTexts = localStorage.getItem(sStorageKey) || '';
    if (!sJsonStoredTexts)
        return {};
    return JSON.parse(sJsonStoredTexts) || {};
};
export const fnUpdateStoredMetaData = (oMetaData, sPageName, sLanguage) => {
    const fnUpperStart = (sWord) => sWord.charAt(0).toUpperCase() + sWord.slice(1);
    const sPageNameCase = sPageName.split('_').map(sPart => fnUpperStart(sPart)).join('');
    const sLanguageCamelCase = fnUpperStart(sLanguage);
    const sStorageKey = `storedMeta${sLanguageCamelCase}${sPageNameCase}`;
    localStorage.setItem(sStorageKey, JSON.stringify(oMetaData));
};
const oFields = {
    artists_app: [
        'accept_text',
        'accept_password',
        'accept_contains_characters',
        'accept_contains_number',
        'accept_contains_uppercase',
        'accept_contains_special_chars',
        'accept_set_password',
        'artwork_title',
        'artwork_status',
        'artwork_status_private',
        'artwork_status_public',
        'artwork_description',
        'artwork_save',
        'artwork_delete',
        'artwork_in_process',
        'artwork_text_file_formats',
        'artwork_choose_file',
        'login_text',
        'login_email',
        'login_password',
        'login_no_account_yet',
        'login_login',
        'login_sign_up',
        'login_msg_incorrect_credentials',
        'login_faq',
        'posts_new_artwork',
        'posts_artwork_title',
        'posts_submit_artwork',
        'posts_no_posts_error',
        'profile_choose_file',
        'profile_first_name',
        'profile_last_name',
        'profile_birth_year',
        'profile_country',
        'profile_about',
        'profile_facebook',
        'profile_twitter',
        'profile_instagram',
        'profile_youtube',
        'profile_vimeo',
        'profile_tiktok',
        'profile_save',
        'register_title',
        'register_welcome_text',
        'register_terms',
        'register_already_have_account',
        'register_register',
        'register_login',
        'register_register_response',
        'register_faq',
        'register_email',
        'request_pw_email',
        'request_pw_reset',
        'reset_pw_email',
        'reset_pw_reset',
        'artwork_update_success',
        'artwork_rly_delete_artwork',
        'artwork_cant_delete_post',
        'artwork_image_upload_success',
        'artwork_import_filetype_impossible',
        'internal_error',
        'login_logout_success',
        'profile_url_pattern_invalid',
        'profile_update_success',
        'profile_image_upload_success',
        'profile_image_delete_success',
        'register_email_already_registered',
        'accept_password_repeat',
        'artwork_size',
        'artwork_size_unit',
        'profile_missing_informations',
        'profile_missing_informations_btn',
        'profile_country_label_others',
        'profile_country_label_afrika',
        'login_password_reset'
    ],
    artists_app_2: [
        'exhibition_select_artwork',
        'exhibition_walkthrough',
        'exhibition_map',
        'exhibition_edit_exhibition',
        'exhibition_available_artworks',
        'exhibition_my_selection',
        'exhibition_add',
        'exhibition_remove',
        'exhibition_set_location',
        'exhibition_location',
        'exhibition_status',
        'exhibition_status_text_none',
        'exhibition_status_text_indoor',
        'exhibition_status_text_outdoor',
        'exhibition_status_download_marker',
        'exhibition_dropzone',
        'exhibition_location_indoor_description',
        'exhibition_location_outdoor_description',
        'exhibitionedit_text_file_formats',
        'exhibitionedit_choose_file',
        'exhibitionedit_update_success',
        'exhibitionedit_rly_delete_exhibition',
        'exhibitionedit_status_published',
        'exhibitionedit_status_draft',
        'exhibitionedit_status_archived',
        'exhibitionedit_status',
        'exhibitionedit_name',
        'exhibitionedit_description',
        'exhibitionedit_start_date',
        'exhibitionedit_end_date',
        'exhibitionedit_city',
        'exhibitionedit_location',
        'exhibitionedit_submit',
        'exhibitionedit_delete',
        'exhibitions_new_exhibition',
        'exhibitions_exhibition_title',
        'exhibitions_submit_exhibition',
        'exhibitions_no_exhibitions_error',
        'exhibitionedit_image_upload_success',
        'exhibitionedit_cant_delete_post',
        'exhibition_import_filetype_impossible',
        'exhibitions_action_edit',
        'exhibitions_action_statistics',
        'exhibitions_action_artworks',
        'request_pw_success',
        'request_pw_error',
        'reset_pw_success',
        'profile_website',
        'profile_url_pattern_invalid_start',
        'accept_passwords_unequal',
        'login_suspended',
        'artwork_placing',
        'artwork_placing_floor',
        'artwork_placing_wall',
        'artwork_progress_upload_file',
        'artwork_progress_generate_model',
        'artwork_progress_remove_file',
        'login_welcome',
        'login_registered_incomplete',
        'accept_validation_error',
        'artwork_cat_painting',
        'artwork_cat_printmaking',
        'artwork_cat_sculpture',
        'artwork_cat_photo',
        'artwork_cat_video',
        'exhibition_how_to'
    ],
    artists_app_3: [
        'artwork_cat_animation',
        'artwork_cat_digitalart',
        'artwork_category',
        'artwork_delete_not_possible',
        'exhibition_location_type',
        'exhibition_location_group',
        'exhibition_location_range',
        'exhibition_location_sizing',
        'exhibition_sizing_auto',
        'exhibition_sizing_fixed',
        'artwork_status_exhibition',
        'artwork_statusdesc_private',
        'artwork_statusdesc_public',
        'artwork_statusdesc_exhibition',
        'maximum_text_size_exceeded',
        'artwork_model_recreate',
        'artwork_size_onchange',
        'viewer_gps_disabled',
        'viewer_refresh_page',
        'viewer_view_artwork',
        'viewer_artwork_indoor',
        'viewer_artwork_outdoor',
        'viewer_artwork_outdoor_reached',
        'viewer_share_title',
        'viewer_share_text',
        'viewer_intro_text',
        'viewer_intro_button',
        'viewer_translated_tag',
        'viewer_text_original',
        'viewer_text_translated',
        'viewer_ignore',
        'viewer_scanner_hint',
        'viewer_map_hint',
        'viewer_msg_not_started',
        'viewer_msg_ended',
        'viewer_msg_not_available',
        'viewer_enter_exhibition',
        'viewer_details',
        'viewer_reset_app',
        'viewer_camera_missing',
        'viewer_android_version_warning',
        'viewer_android_hint',
        'viewer_ios_version_warning',
        'viewer_loading_model',
        'viewer_replaced_link',
        'viewer_map_hint_outdoor_only',
        'viewer_use_chrome'
    ]
};
export const fnSetPageInformations = async (_this, oTextTemplate) => {
    const aFn = [];
    const aFnAwait = [];
    const oTextTemplateCopy = JSON.parse(JSON.stringify(oTextTemplate));
    const aPromise = [
        'artists_app',
        'artists_app_2',
        'artists_app_3'
    ].map(sKey => {
        const oTemplate = {};
        Object.keys(oTextTemplateCopy)
            .filter(sKeyTemplate => oFields[sKey].indexOf(sKeyTemplate) !== -1)
            .forEach(sKeyTemplate => {
            oTemplate[sKeyTemplate] = oTextTemplate[sKeyTemplate];
            delete oTextTemplateCopy[sKeyTemplate];
        });
        return fnGetTexts(_this.localStorage.L, sKey, Object.keys(oTemplate));
    });
    const fnRequestTexts = async () => {
        const [...aTexts] = await Promise.all(aPromise);
        aTexts.forEach(oTexts => _this.text = {
            ..._this.text,
            ...oTexts
        });
        fnUpdateStoredTexts(_this.text, _this.localStorage.L);
    };
    const fnRequestMetaData = async () => {
        const oMetaData = await fnGetMetaData(_this.metaData.page, _this.localStorage.L);
        fnUpdateStoredMetaData(oMetaData, _this.metaData.page, _this.localStorage.L);
        _this.metaData.content = oMetaData;
    };
    const oStoredTexts = fnGetStoredTexts(oTextTemplate, _this.localStorage.L);
    const oStoredMetaData = fnGetStoredMetaData(_this.metaData.page, _this.localStorage.L);
    _this.text = { ..._this.text, ...oStoredTexts };
    _this.metaData.content = oStoredMetaData;
    !Object.keys(oStoredTexts).length ? aFnAwait.push(fnRequestTexts) : aFn.push(fnRequestTexts);
    !Object.keys(oStoredMetaData).length ? aFnAwait.push(fnRequestMetaData) : aFn.push(fnRequestMetaData);
    if (aFn.length)
        fnRunSimultaneously(aFn, `${_this.metaData.page} | Async requests`);
    if (aFnAwait.length)
        await fnRunSimultaneously(aFnAwait, `${_this.metaData.page} | Awaited requests`);
};
export const fnSetCssVariables = () => {
    $('html').css({
        '--vh': `${window.innerHeight / 100}px`,
        '--vw': `${window.innerWidth / 100}px`
    });
};
export const fnGetBgGradient = () => {
    const aBgColors = [
        [120, 30, 0],
        [0, 100, 200]
    ];
    const aBgGradients = [
        'radial-gradient(at __percent__%, rgba(__color__, __opacity__) 0%, rgba(21, 59, 76, __opacity__) 80%)',
        'linear-gradient(__rotation__deg, rgba(__color__, __opacity__) 0%, rgba(21, 59, 76, __opacity__) 100%)',
        'linear-gradient(__rotation__deg, rgba(__color__, __opacity__) 0%, rgba(21, 59, 76, __opacity__) 100%)'
    ];
    if (!aBgColors.length || !aBgGradients.length)
        return '';
    const fnRandArray = (aElem) => Math.floor(Math.random() * aElem.length);
    const fnRandMinMax = (iMin, iMax) => Math.floor(Math.random() * (iMax - iMin + 1) + iMin);
    const sTemplate = aBgGradients[fnRandArray(aBgGradients)];
    const sOpacity = '0.92';
    let sGradient = sTemplate;
    while (sGradient.indexOf('__color__') !== -1) {
        const sReplace = aBgColors[fnRandArray(aBgColors)].join(', ');
        sGradient = sGradient.replace('__color__', sReplace);
    }
    while (sGradient.indexOf('__rotation__') !== -1) {
        const sReplace = fnRandMinMax(-179, 180).toString();
        sGradient = sGradient.replace('__rotation__', sReplace);
    }
    while (sGradient.indexOf('__percent__') !== -1) {
        const sReplace = fnRandMinMax(0, 100).toString();
        sGradient = sGradient.replace('__percent__', sReplace);
    }
    while (sGradient.indexOf('__opacity__') !== -1)
        sGradient = sGradient.replace('__opacity__', sOpacity);
    return sGradient;
};
export const fnShareContent = (sTitle, sUrl, sText, fn) => {
    const fnShareSuccess = fn?.success || (() => console.log('Share | Success'));
    const fnShareError = fn?.error || (oError => console.log('Share | Error', oError));
    const fnShareNoSupport = fn?.noSupport || (() => console.log('Share | No browser support'));
    navigator.share
        ? navigator.share({ title: sTitle, url: sUrl, text: sText })
            .then(fnShareSuccess)
            .catch(fnShareError)
        : fnShareNoSupport();
};
export const fnGetDocumentHidden = () => {
    if (typeof document.hidden !== 'undefined')
        return {
            visibilityChange: 'visibilitychange',
            state: 'visibilityState'
        };
    if (typeof document.mozHidden !== 'undefined')
        return {
            visibilityChange: 'mozvisibilitychange',
            state: 'mozVisibilityState'
        };
    if (typeof document.msHidden !== 'undefined')
        return {
            visibilityChange: 'msvisibilitychange',
            state: 'msVisibilityState'
        };
    if (typeof document.webkitHidden !== 'undefined')
        return {
            visibilityChange: 'webkitvisibilitychange',
            state: 'webkitVisibilityState'
        };
    return null;
};
export const fnRegisterCustomDropdownEvents = () => {
    $('.custom-dropdown button[aria-haspopup="listbox"]').on('click', oEvent => {
        const $target = $(oEvent.target);
        const $elem = $target.attr('aria-haspopup') !== 'listbox' ? $target.parents('[aria-haspopup="listbox"]') : $target;
        const bExpanded = JSON.parse($elem.attr('aria-expanded') || 'false');
        $elem.attr('aria-expanded', JSON.stringify(!bExpanded));
        $elem.parent().children('[role="listbox"]').toggleClass('hidden');
    });
    $('.custom-dropdown [role="option"]').on('click', oEvent => {
        const $target = $(oEvent.target);
        const $elem = $target.attr('role') !== 'option' ? $target.parents('[role="option"]') : $target;
        $elem
            .parents('.custom-dropdown')
            .find('[aria-haspopup="listbox"]')
            .html($elem.attr('data-label') || '');
        $('.custom-dropdown button[aria-haspopup="listbox"]').trigger('click');
    });
    $('#body *:not(.custom-dropdown)').on('click', oEvent => {
        if ($(oEvent.target).parents('.custom-dropdown').length)
            return;
        const $dropDown = $('.custom-dropdown');
        $dropDown.find('[aria-haspopup="listbox"]').attr('aria-expanded', 'false');
        $dropDown.find('[role="listbox"]').addClass('hidden');
    });
};
export const fnRegisterMenuButtonEvents = () => {
    $('.menu-button').on('click', oEvent => {
        const $target = $(oEvent.target);
        const $elem = $target.hasClass('menu-button') ? $target : $target.parents('.menu-button');
        const bIsOpened = !$elem.hasClass('opened');
        $elem.toggleClass('opened');
        $elem.attr('aria-expanded', bIsOpened.toString());
        $('body')[bIsOpened ? 'addClass' : 'removeClass']('block-scroll-s');
    });
};
export const fnRegisterRedirectClickEvent = () => {
    $('[data-redirect-click]').on('click', (oEvent) => {
        const [sTargetId, sEvent] = $(oEvent.target).attr('data-redirect-event')?.split(':') || [];
        if (sTargetId)
            $(`#${sTargetId}`).trigger(sEvent || oEvent.type);
    });
};
export const fnRequestDeviceOrientation = async () => {
    const sState = await new Promise((resolve) => {
        if (DeviceOrientationEvent && typeof DeviceOrientationEvent.requestPermission === 'function') {
            DeviceOrientationEvent.requestPermission()
                .then((sPermissionState) => resolve(sPermissionState))
                .catch((sError) => {
                console.log(sError);
                resolve('error');
            });
        }
        else {
            resolve('granted');
        }
    });
    return sState === 'granted';
};
export default {
    oMetaDataTemplate: oMetaDataTemplate,
    oRoles: oRoles,
    oMessageBoxDataTemplate: oMessageBoxDataTemplate,
    fnGetTexts: fnGetTexts,
    fnGetMetaData: fnGetMetaData,
    fnRunSimultaneously: fnRunSimultaneously,
    fnCloseMenu: fnCloseMenu,
    fnGetStoredTexts: fnGetStoredTexts,
    fnUpdateStoredTexts: fnUpdateStoredTexts,
    fnGetStoredMetaData: fnGetStoredMetaData,
    fnUpdateStoredMetaData: fnUpdateStoredMetaData,
    fnSetCssVariables: fnSetCssVariables,
    fnGetBgGradient: fnGetBgGradient,
    fnShareContent: fnShareContent,
    fnGetDocumentHidden: fnGetDocumentHidden,
    fnRegisterCustomDropdownEvents: fnRegisterCustomDropdownEvents,
    fnRegisterMenuButtonEvents: fnRegisterMenuButtonEvents,
    fnRegisterRedirectClickEvent: fnRegisterRedirectClickEvent,
    fnRequestDeviceOrientation: fnRequestDeviceOrientation
};
