<template>
  <div id="body">
    <Header :onLanguageChange="init"/>

    <div class="list">
      <div class="msg" v-if="showInactiveMessage">
        <h2 v-if="inactiveReason === 's'">{{ text.viewer_msg_not_started }}</h2>
        <h2 v-else-if="inactiveReason === 'e'">{{ text.viewer_msg_ended }}</h2>
        <h2 v-else>{{ text.viewer_msg_not_available }}</h2>
      </div>

      <ExhibitionCard v-for="exhibition in (exhibitions || [])" :key="exhibition.id" :data="exhibition" :text="text" :language="localStorage.L" />
    </div>
  </div>
</template>

<script>
import { oMetaDataTemplate, fnSetPageInformations, fnCloseMenu } from '@/modules/globalFunctions.js';
import { mapGetters, mapActions } from 'vuex';
import Header from '@/components/Header.vue';
import ExhibitionCard from '@/components/ExhibitionCard.vue';

const oTextTemplate = {
  viewer_msg_not_started: '',
  viewer_msg_ended: '',
  viewer_msg_not_available: '',
  general_enter_exhibition: '',
  general_view_details: ''
};

export default {
  name: 'List',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    Header,
    ExhibitionCard
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'ar_viewer',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      exhibitions: [],
      showInactiveMessage: false,
      inactiveReason: null
    };
  },
  watch: {
    $route() {
      this.init();
      fnCloseMenu();
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({ Exhibitions: 'StateExhibitions' })
  },
  methods: {
    ...mapActions(['GetExhibitions']),

    async init() {
      this.fetchLocalStorage();

      const oUrlParams = new URLSearchParams(location.search);
      this.showInactiveMessage = oUrlParams.has('inactive');
      this.inactiveReason = this.showInactiveMessage ? oUrlParams.get('inactive') : null; // 's' | 'e' | null

      await fnSetPageInformations(this, oTextTemplate);
      await this.GetExhibitions(this.localStorage.L);
      this.exhibitions = this.Exhibitions || [];
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../scss/_list.scss";
</style>
