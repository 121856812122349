<template>
  <div class="exhibition-preview" :style="{ 'background-image': getGradient() }">
    <header>
      <h2>{{ data.name }}</h2>
    </header>
    <div class="image">
      <img :src="data.image" :alt="data.name" draggable="false" width="auto" height="auto"/>
    </div>
    <div class="text">
      <span v-html="data.description"></span>
      <div class="enter-exhibition-btn">
        <router-link v-if="data.active" class="btn" :to="`/${data.id}`">{{ text.viewer_enter_exhibition }}</router-link>
        <a class="btn" :href="`https://arxafrica.net/${language}/${data.alias || ('exhibition/' + data.id)}`">{{ text.viewer_details }}</a>
      </div>
    </div>
    <footer class="bold">
      <p>{{ data.start_date }} - {{ data.end_date }}</p>
      <p><span>{{ data.city }}</span> <span>{{ data.location }}</span></p>
    </footer>
  </div>
</template>

<script>
import { fnGetBgGradient } from '@/modules/globalFunctions.js';

export default {
  name: 'ExhibitionCard',
  props: {
    data: Object,
    text: Object,
    language: String
  },
  data() {
    return {
      gradient: ''
    };
  },
  methods: {
    /**
     * Create a random background gradient
     *
     * @returns {string} CSS value for property 'background-image'
     */
    getGradient() {
      return fnGetBgGradient();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/_exhibitioncard.scss";
</style>
