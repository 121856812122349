<template>
  <div id="body" class="light">
    <Header :onLanguageChange="init"/>

    <div v-if="localStorage && localStorage.L === 'en'" class="requirements">
      <h3>Technical requirements for visiting the exhibition</h3>
      <p>You will be able to visit the exhibition with almost any mobile Android or Apple device. You do not need an additional app.</p>
      <p>&nbsp;</p>
      <p><strong>In general //</strong> Please allow your <strong>location recognition</strong> and <strong>access to the camera</strong> of your smartphone or tablet. Experiencing the artworks generates increased <strong>energy consumption</strong>. So charge your device fully and ideally take a <strong>powerbank</strong> with you - and some patience on site to interact with the exhibition.</p>
      <p>&nbsp;</p>
      <p><strong>For Android //</strong> You need a device with <strong>Android 7.0 Nougat or higher</strong>. The latest version is Android 12. Your device must support ARCore and have the latest version of Google Play Services for AR. This version is already automatically installed on most suitable devices.</p>
      <p>&nbsp;</p>
      <p><strong>For Apple iOS //</strong> You need a device with <strong>iOS 12 or higher</strong>. The current version is iOS 15.</p>

      <div class="device-tests">
        <p>Test here whether your smartphone/tablet meets the technical requirements:</p>
        <ul>
          <li class="state-elements">
            <span class="state-icon inline-svg"><Icon :name="isMobileTablet ? 'check' : 'cross'" /></span
            >&ensp;<span v-if="isMobileTablet">Device is a smartphone&nbsp;/&nbsp;tablet</span
            ><span v-else>Device is no smartphone&nbsp;/&nbsp;tablet</span>
          </li>
          <li class="state-elements">
            <span class="state-icon inline-svg"><Icon :name="isAllowedBrowser ? 'check' : 'cross'" /></span
            >&ensp;<span v-if="isAllowedBrowser">Browser is Google Chrome&nbsp;/&nbsp;Safari</span
            ><span v-else>Browser is not Google Chrome&nbsp;/&nbsp;Safari</span>
          </li>
          <li class="state-elements state-ar-support false">
            <iframe src="https://arxafrica.net/devicetest" v-if="supportsAr">
              <a href="https://arxafrica.net/devicetest" target="_blanc" class="btn secondary fit-width">Try to place an AR object</a>
            </iframe>
            <p v-if="supportsAr">If no 3D model opens after clicking this button, your device cannot be used in this exhibition.</p>
            <span v-else><span class="state-icon inline-svg"><Icon name="cross" /></span>&ensp;Device does not support AR</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="localStorage && localStorage.L === 'de'" class="requirements">
      <h3>Technische Voraussetzungen f&uuml;r den Ausstellungsbesuch</h3>
      <p>In der Regel werden Sie die Ausstellung mit nahezu jedem mobilen Android- oder Apple-Ger&auml;t besuchen k&ouml;nnen. Sie ben&ouml;tigen keine zus&auml;tzliche App.</p>
      <p>&nbsp;</p>
      <p><strong>Generell //</strong>&nbsp;Erlauben Sie bitte Ihre <strong>Standorterkennung </strong>und den <strong>Zugriff auf die Kamera</strong> Ihres Smartphones oder Tablets. Das Erleben der Kunstwerke erzeugt einen erh&ouml;hten <strong>Energieverbrauch</strong>. Laden Sie Ihr Ger&auml;t also vollst&auml;ndig auf und nehmen Sie idealerweise eine <strong>Powerbank </strong>mit &ndash; und etwas Geduld vor Ort, um mit der Ausstellung zu interagieren.&nbsp;</p>
      <p>&nbsp;</p>
      <p><strong>F&uuml;r Android // </strong>Sie ben&ouml;tigen ein Ger&auml;t mit <strong>Android 7.0 Nougat oder h&ouml;her</strong>. Die aktuellste Version ist Android 12. Ihr Ger&auml;t muss ARCore unters&uuml;tzen und &uuml;ber die neueste Version des Google Play Services f&uuml;r AR verf&uuml;gen. Diese Version ist auf den meisten geeigneten Ger&auml;ten bereits automatisch installiert.</p>
      <p>&nbsp;</p>
      <p><strong>F&uuml;r Apple iOS //</strong> Sie ben&ouml;tigen ein Ger&auml;t mit <strong>iOS 12 oder h&ouml;her</strong>. Die aktuelle Version ist iOS 15.</p>

      <div class="device-tests">
        <p>Testen Sie hier, ob Ihr Smartphone/Tablet die technischen Voraussetzungen erfüllt:</p>
        <ul>
          <li class="state-elements">
            <span class="state-icon inline-svg"><Icon :name="isMobileTablet ? 'check' : 'cross'" /></span
            >&ensp;<span v-if="isMobileTablet">Gerät ist ein Smartphone&nbsp;/&nbsp;Tablet</span
            ><span v-else>Gerät ist kein Smartphone&nbsp;/&nbsp;Tablet</span>
          </li>
          <li class="state-elements">
            <span class="state-icon inline-svg"><Icon :name="isAllowedBrowser ? 'check' : 'cross'" /></span
            >&ensp;<span v-if="isAllowedBrowser">Browser ist Google Chrome&nbsp;/&nbsp;Safari</span
            ><span v-else>Browser ist nicht Google Chrome&nbsp;/&nbsp;Safari</span>
          </li>
          <li class="state-elements state-ar-support false">
            <iframe src="https://arxafrica.net/devicetest" v-if="supportsAr">
              <a href="https://arxafrica.net/devicetest" target="_blanc" class="btn secondary fit-width">Versuche ein AR Objekt anzusehen</a>
            </iframe>
            <p v-if="supportsAr">Wenn sich nach der Betätigung dieser Schaltfläche kein 3D-Modell öffnet, ist Ihr Gerät leider nicht in dieser Ausstellung verwendbar.</p>
            <span v-else><span class="state-icon inline-svg"><Icon name="cross" /></span>&ensp;Gerät unterstützt AR leider nicht</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="localStorage && localStorage.L === 'fr'" class="requirements">
      <h3>Conditions techniques pour visiter l'exposition</h3>
      <p>&nbsp;</p>
      <p>Vous pourrez visiter l'exposition avec presque tous les appareils mobiles Android ou Apple. Vous n'avez pas besoin d'une application suppl&eacute;mentaire.</p>
      <p>&nbsp;</p>
      <p><strong>En g&eacute;n&eacute;ral //</strong> Veuillez autoriser la <strong>reconnaissance de votre emplacement</strong> et <strong>l'acc&egrave;s &agrave; la cam&eacute;ra</strong> de votre smartphone ou tablette. L'exp&eacute;rience des &oelig;uvres d'art g&eacute;n&egrave;re une consommation d'&eacute;nergie accrue. Chargez donc votre appareil &agrave; fond et emportez id&eacute;alement un <strong>powerbank </strong>- et un peu de patience sur place pour interagir avec l'exposition.</p>
      <p>&nbsp;</p>
      <p><strong>Pour Android //</strong> Vous devez disposer d'un appareil &eacute;quip&eacute; <strong>d'Android 7.0 Nougat ou d'une version sup&eacute;rieure</strong>. La version la plus r&eacute;cente est Android 12. Votre appareil doit prendre en charge ARCore et disposer de la derni&egrave;re version de Google Play Services for AR. Cette version est d&eacute;j&agrave; install&eacute;e automatiquement sur la plupart des appareils adapt&eacute;s.</p>
      <p>&nbsp;</p>
      <p><strong>Pour Apple iOS //</strong> Vous devez disposer d'un appareil &eacute;quip&eacute; <strong>d'iOS 12 ou d'une version sup&eacute;rieure</strong>. La version actuelle est iOS 15.</p>

      <div class="device-tests">
        <p>Test here whether your smartphone/tablet meets the technical requirements:</p>
        <ul>
          <li class="state-elements">
            <span class="state-icon inline-svg"><Icon :name="isMobileTablet ? 'check' : 'cross'" /></span
            >&ensp;<span v-if="isMobileTablet">Device is a smartphone&nbsp;/&nbsp;tablet</span
            ><span v-else>Device is no smartphone&nbsp;/&nbsp;tablet</span>
          </li>
          <li class="state-elements">
            <span class="state-icon inline-svg"><Icon :name="isAllowedBrowser ? 'check' : 'cross'" /></span
            >&ensp;<span v-if="isAllowedBrowser">Browser is Google Chrome&nbsp;/&nbsp;Safari</span
            ><span v-else>Browser is not Google Chrome&nbsp;/&nbsp;Safari</span>
          </li>
          <li class="state-elements state-ar-support false">
            <iframe src="https://arxafrica.net/devicetest" v-if="supportsAr">
              <a href="https://arxafrica.net/devicetest" target="_blanc" class="btn secondary fit-width">Try to place an AR object</a>
            </iframe>
            <p v-if="supportsAr">If no 3D model opens after clicking this button, your device cannot be used in this exhibition.</p>
            <span v-else><span class="state-icon inline-svg"><Icon name="cross" /></span>&ensp;Device does not support AR</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { oMetaDataTemplate, fnCloseMenu } from '@/modules/globalFunctions.js';
import Header from '@/components/Header.vue';
import Icon from '@/components/Icon.vue';

export default {
  name: 'Requirements',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    Header,
    Icon
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'ar_viewer',
        content: oMetaDataTemplate
      },
      isMobileTablet: false,
      isAllowedBrowser: false,
      supportsAr: false
    };
  },
  watch: {
    $route() {
      this.init();
      fnCloseMenu();
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.fetchLocalStorage();

      const sUserAgent = navigator.userAgent || navigator.vendor || window.opera || null;
      const sUserAgentPart = sUserAgent.substr(0, 4);

      this.isMobileTablet = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(sUserAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(sUserAgentPart);
      this.isAllowedBrowser = /^((?!chrome|android).)*safari/i.test(sUserAgent) || /chrome/i.test(sUserAgent);
      this.supportsAr = (document.createElement('a').relList.supports('ar') && window.SpeechSynthesisUtterance) || /android/i.test(sUserAgent);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../scss/_requirements.scss";
</style>

<style lang="scss">
@import "./../scss/variables";

html,
body {
  background-image: $background-horizontal;
}
</style>
